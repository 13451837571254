import React, { useState, useEffect } from "react";
import { Item } from "../../../../_models/Report.model";
import { Button, Col, FormControl, Image, Modal, Row } from "react-bootstrap";
import { ClientItemScoring } from "./ClientItemScoring";
import { useReportContext } from "../../../../_context/ReportContext";
import { FaCamera, FaEraser, FaSave } from "react-icons/fa";
import Camera from "../../../../_camera/Camera";
import S3Uploader from "../../../../_aws/S3Uploader";

interface clientItemProps {
  item: Item;
  itemIndex: number;
  handleScoreUpdate: (itemIndex: number, newScore: number) => void;
  handleCommentUpdate: (itemIndex: number, newComment: string) => void;
  handleImageUpdate: (itemIndex: number, images: string[]) => void;
}

export const ClientItem: React.FC<clientItemProps> = ({
  item,
  itemIndex,
  handleScoreUpdate,
  handleCommentUpdate,
  handleImageUpdate,
}) => {
  const { newReport, setNewReport } = useReportContext();
  const [imageList, setImageList] = useState<string[]>([]);
  const [imagesToUpload, setImagesToUpload] = useState<string[]>([]);
  const [showCameraModal, setShowCameraModal] = useState(false);


  useEffect(() => {
    setImageList(item.Images || []);
  }, [item.Images]);

  const handleCapture = (imageData: string) => {
    if (!imagesToUpload.includes(imageData)) {
        setImagesToUpload(prev => [...prev, imageData]);
      }
};

const handleUploadSuccess = (url: string) => {
    setImageList(prevImages => [...prevImages, url]);
    handleImageUpdate(itemIndex, [...imageList, url]);
    // Remove the image from imagesToUpload
    setImagesToUpload(prev => prev.filter(image => image !== url));
  };

const removeImage = (imageToRemove: string) => {
    const updatedImages = imageList.filter(image => image !== imageToRemove);
    setImageList(updatedImages);
    handleImageUpdate(itemIndex, updatedImages);
};

  return (
    <>
      <Row className="mt-4" key={item.Name}>
        <Col sm={4} className="mb-2">
          {item.Name}
        </Col>
        <Col sm={3} className="mb-2">
          <ClientItemScoring
            itemScore={item.Mark}
            updateScore={(newScore) => handleScoreUpdate(itemIndex, newScore)}
          />
        </Col>
        <Col sm={4} className="mb-2">
          <FormControl
            placeholder="Comments"
            className="w-100"
            value={item.Comment || ""} // Bind the value to the item's comment
            onChange={(e) => handleCommentUpdate(itemIndex, e.target.value)} // Update comment on change
          />
        </Col>
        <Col>
          <Button
            className="w-100"
            variant="info"
            onClick={() => setShowCameraModal(true)}
          >
            <FaCamera className="camaraImage" />
          </Button>
        </Col>
        <Col sm={12} className="mt-2">
          <Row>
            {imageList?.map((image,index) => (
              <Col sm={3}>
                <Image key={`${item.Name}${index}`} src={image} className="w-100" />
                <Row className="mt-1">
                  <Col xs={4}>
                    <Button
                      variant="danger"
                      className="w-100"
                      onClick={() => removeImage(image)}
                    >
                      <FaEraser />
                    </Button>
                  </Col>
                </Row>
              </Col>
            ))}
            {imagesToUpload.map((image, index) => (
        <S3Uploader
        key={index}
        image={image}
        imageName={`${Date.now()}.jpg`}
        folderName={newReport.ImageDirectory}
        s3ClientName={`${newReport.Client.Name}-${newReport.ClientName}`}
        bucketName={
          process.env.REACT_APP_AWS_S3_BUCKET_NAME as string
        }
        onUploadSuccess={handleUploadSuccess}
        onUploadError={(error) => console.error("Upload failed:", error)}
        />
      ))}
            
          </Row>
        </Col>
      </Row>
      <Modal
        show={showCameraModal}
        onHide={() => setShowCameraModal(false)}
        centered
        className="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="camHeader">Take a photo</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <Camera
            onCapture={handleCapture}
            onCaptureCompleted={() => setShowCameraModal(false)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
