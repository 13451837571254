import React, { useEffect, useState } from "react";
import { Col, FormControl, Row, Table } from "react-bootstrap";
import { Cleaner } from "../../../../_models/Cleaner.model";
import { getAllCleaners } from "../../../../_utils/resourceAPI";
import { FaClipboardList } from "react-icons/fa6";
import { UpdateCleaner } from "../UpdateCleaner/UpdateCleaner";
import { useCleanerContext } from "../../../../_context/CleanerContext";
import { CleanerUpdatedSuccessModal } from "../UpdateCleaner/CleanerUpdatedSuccessModal";
import { CleanerDeleteSuccessModal } from "../UpdateCleaner/ClientDeletedSuccessModal";


export const AddedCleaners = () => {
  const [allCleaners, setAllCleaners] = useState<Cleaner[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const {setCleanerUpdated,setCleanerDeleted} = useCleanerContext()

  const fetchCleaners = () => {
    getAllCleaners()
      .then((res) => {
        const allCleaners: Cleaner[] = res.data;
        setAllCleaners(allCleaners);
      })
      .catch((error) => {
        console.log("Failed to fetch cleaners");
      });
  };

  useEffect(() => {
    fetchCleaners();
  }, []);


  const onCleanerUpdate = () => {
    fetchCleaners();
    setCleanerUpdated(true)
  };


  const onCleanerDelete = () => {
    fetchCleaners();
    setCleanerDeleted(true)
  };
  

  const filteredCleaners = allCleaners.filter(
    (cleaner) =>
      cleaner.Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      cleaner.Phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
      cleaner.Email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Row>
      <Col sm={12}>
        <h5><FaClipboardList /> CNA Cleaners List</h5>
        <hr/>
      </Col>
      <Col>
        <FormControl
          type="text"
          placeholder="Search by name, phone or email..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="mb-1"
        />
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredCleaners.map((cleaner) => {
              return cleaner ? (
                <tr key={cleaner.Id}>
                  <td>{cleaner.Name}</td>
                  <td>{cleaner.Phone}</td>
                  <td>{cleaner.Email}</td>
                  <td><UpdateCleaner
                existingCleaner={cleaner}
                onCleanerupdate={onCleanerUpdate}
                onCleanerDelete={onCleanerDelete}
                  /></td>
                </tr>
              ) : null;
            })}
          </tbody>
        </Table>
      </Col>
      <CleanerUpdatedSuccessModal/>
      <CleanerDeleteSuccessModal/>
    </Row>
  );
};
