import React from 'react'
import { SubArea } from '../../../../../_models/Report.model'
import { Col, Row } from 'react-bootstrap'
import { ReportItemView } from './ReportItemView'
import { forEachChild } from 'typescript'

interface ReportSubAreaProps{
    SubAreas:SubArea[]
}


const subAreaHasApplicalbeItems = (subArea:SubArea) => {
  let totalScore = 0 ;

  subArea.Items.forEach(item => {
    totalScore += item.Mark
  })

  return totalScore > 0

}

export const ReportSubAreaView:React.FC<ReportSubAreaProps> = ({SubAreas}) => {
  return (
    <>
    {SubAreas.map(subArea => (
        <Row>
            <span className='reportSubAreaName'>
                {subArea.Name}
            </span>
            {subAreaHasApplicalbeItems(subArea) ?
            <Col>
            <ReportItemView Items={subArea.Items} />
            </Col>
          :
          <Col className='mb-3 text-secondary'>
          <small>
          This section does not apply for the inspected facility
          </small>
          
          </Col>
          
          }
            
            
        </Row>
    ))}
    </>
  )
}
