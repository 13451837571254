import React, { useState } from "react";
import { Form } from "react-bootstrap";

interface InputComponentProps {
  id: string;
  label: string;
  type: string;
  required?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  validator?: (
    value: string,
    isRequired: boolean,
    inputName: string
  ) => string | null;
}

export const FormInput: React.FC<InputComponentProps> = ({
  id,
  label,
  type,
  required = false,
  value = "",
  onChange,
  onBlur,
  validator,
}) => {
  const [error, setError] = useState<string | null>(null);

  const validate = (valueToValidate: string) => {
    if (validator) {
      return validator(valueToValidate, required, label);
    }
    return null;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (onChange) onChange(inputValue);
  };

  const handleBlur = () => {
    setError(validate(value));
    if (onBlur) onBlur();
  };

  return (
    <Form.Group controlId={id} className="form-group-class">
      <Form.Label>
        <strong>{label}</strong>
      </Form.Label>
      <Form.Control
        type={type}
        placeholder={label}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        isInvalid={Boolean(error)}
        required={required}
      />
      {error && (
        <Form.Control.Feedback type="invalid">
          <small>{error}</small>
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};
