import React, { ReactNode, createContext, useContext, useState } from "react";
import { Report } from "../_models/Report.model";

interface ReportContextProps {
  newReport: Report;
  setNewReport: React.Dispatch<React.SetStateAction<Report>>;
  currentViewingReport: Report;
  setCurrentViewingReport: React.Dispatch<React.SetStateAction<Report>>;
  filteredInternalReportsForSelectedClient: Report[];
  setFilteredInternalReportsForSelectedClient: React.Dispatch<
    React.SetStateAction<Report[]>
  >;
  filteredInspectionReportsForSelectedClient: Report[];
  setFilteredInspectionReportsForSelectedClient: React.Dispatch<
    React.SetStateAction<Report[]>
  >;
  printableReportPageHeight: number;
  setPrintableReportPageHeight: React.Dispatch<React.SetStateAction<number>>;
  updateReportedDate: () => void;
  areaBasedScoringAreabuttonsClicked: { [key: string]: string | null };
  setAreaBasedScoringAreabuttonsClicked: React.Dispatch<React.SetStateAction<{ [key: string]: string | null }>>;
  isNewReport: boolean;
  setIsNewReport: React.Dispatch<React.SetStateAction<boolean>>;
  fetchedAllReports: Report[]
  setFetchedAllReports: React.Dispatch<
  React.SetStateAction<Report[]>
>;
}




const ReportContext = createContext<ReportContextProps | null>(null);

export const useReportContext = () => {
  const context = useContext(ReportContext);
  if (!context) {
    throw new Error(
      "useReportContext must be used within a report context provider"
    );
  }
  return context;
};

interface ReportContextProviderProps {
  children: ReactNode;
}

export const ReportContextProvider: React.FC<ReportContextProviderProps> = ({
  children,
}) => {
  const [currentViewingReport, setCurrentViewingReport] = useState<Report>(
    () => {
      const savedReport = localStorage.getItem("viewingReport");
      return savedReport ? JSON.parse(savedReport) : ({} as Report);
    }
  );

  const [areaBasedScoringAreabuttonsClicked, setAreaBasedScoringAreabuttonsClicked] = useState<{
    [key: string]: string | null;
  }>(() => {
    const savedState = localStorage.getItem("buttonClicked");
    return savedState ? JSON.parse(savedState) : {};
  });

  const [newReport, setNewReport] = useState<Report>(() => {
    const savedReport = localStorage.getItem("newReport");
    return savedReport ? JSON.parse(savedReport) : ({} as Report);
  });

  const [isNewReport, setIsNewReport] = useState<boolean>(true)

  

  const [
    filteredInternalReportsForSelectedClient,
    setFilteredInternalReportsForSelectedClient,
  ] = useState<Report[]>([]);
  const [
    filteredInspectionReportsForSelectedClient,
    setFilteredInspectionReportsForSelectedClient,
  ] = useState<Report[]>([]);

  const[fetchedAllReports,setFetchedAllReports] = useState<Report[]>([]);

  const [printableReportPageHeight, setPrintableReportPageHeight] =
    useState<number>(0);

  const handleSetViewingReport: React.Dispatch<React.SetStateAction<Report>> = (
    value
  ) => {
    const newViewReport =
      typeof value === "function" ? value(currentViewingReport) : value;

    // Save the new client data to localStorage
    localStorage.setItem("viewingReport", JSON.stringify(newViewReport));

    // Update the client state
    setCurrentViewingReport(newViewReport);
  };

  const handleSetNewReport: React.Dispatch<React.SetStateAction<Report>> = (
    value
  ) => {
    const newCreatingReport =
      typeof value === "function" ? value(newReport) : value;

    console.log("Setting New Report:", newCreatingReport);

    // Save the new report data to localStorage
    localStorage.setItem("newReport", JSON.stringify(newCreatingReport));

    // Update the report state
    setNewReport(newCreatingReport);
  };

  const updateReportedDate = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;
    setNewReport((prevReport) => ({
      ...prevReport,
      ReportedDate: formattedDate,
    }));
  };

  


  const handleSetAreaBasedScoring_AreaButtonsClicked = (value: React.SetStateAction<{ [key: string]: string | null }>) => {
    const newState =
      typeof value === "function" ? value(areaBasedScoringAreabuttonsClicked) : value;

    // Save the new state to localStorage
    localStorage.setItem("buttonClicked", JSON.stringify(newState));

    // Update the state
    setAreaBasedScoringAreabuttonsClicked(newState);
  };

  return (
    <ReportContext.Provider
      value={{
        currentViewingReport,
        setCurrentViewingReport: handleSetViewingReport,
        filteredInternalReportsForSelectedClient,
        setFilteredInternalReportsForSelectedClient,
        printableReportPageHeight,
        setPrintableReportPageHeight,
        filteredInspectionReportsForSelectedClient,
        setFilteredInspectionReportsForSelectedClient,
        newReport,
        setNewReport: handleSetNewReport,
        updateReportedDate,
        areaBasedScoringAreabuttonsClicked,
        setAreaBasedScoringAreabuttonsClicked: handleSetAreaBasedScoring_AreaButtonsClicked,
        isNewReport,
        setIsNewReport,
        fetchedAllReports,setFetchedAllReports
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};
