import { SetStateAction, useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FaArrowRight,FaEye } from "react-icons/fa6";
import { useReportContext } from "../../../../_context/ReportContext";
import { Area } from "../../../../_models/Report.model";
import { Col, Row } from "react-bootstrap";

interface OffCanvasClientListProps {
  clickedAreas: Set<string>;
  setClickedAreas: React.Dispatch<React.SetStateAction<Set<string>>>;
  selectedAreaIndex: number;
  setSelectedAreaInex: React.Dispatch<React.SetStateAction<number>>;
}

export const OffCanvasClientList: React.FC<OffCanvasClientListProps> = ({
  selectedAreaIndex,
  setSelectedAreaInex,
  clickedAreas,
  setClickedAreas
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { newReport } = useReportContext();

  const handleSelecedArea = (areaSelected: Area, areaIndex:number) => {
    setClickedAreas(new Set(clickedAreas).add(areaSelected.Name));
    setSelectedAreaInex(areaIndex)
    handleClose();
  };

  //set the fist area Eye icon always visible
  useEffect(() => {
    if (newReport.Areas.length > 0) {
      setClickedAreas((prevClickedAreas) => {
        const updatedClickedAreas = new Set(prevClickedAreas);
        // Always ensure the first area is visible
        updatedClickedAreas.add(newReport.Areas[0].Name);
  
        // Optional: Remove any areas that no longer exist in newReport.Areas
        prevClickedAreas.forEach(areaName => {
          if (!newReport.Areas.find(area => area.Name === areaName)) {
            updatedClickedAreas.delete(areaName);
          }
        });
  
        return updatedClickedAreas;
      });
    }
  }, [newReport.Areas]);
  

  const handleEyeClick = (areaName: string) => {
    setClickedAreas((prevClickedAreas) => {
      const newClickedAreas = new Set(prevClickedAreas);
      newClickedAreas.delete(areaName);
      return newClickedAreas;
    });
  };

  return (
    <>
    <FaArrowRight className="mb-2 text-success generalOffCanvasArrow" onClick={handleShow} />
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Areas To Inspect</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            {newReport.Areas.map((area, index) => (
              <>
                <Col xs={10} key={area.Name}>
                  <Button
                    variant={
                      newReport.Areas[selectedAreaIndex].Name === area.Name
                        ? "success"
                        : "outline-success"
                    }
                    className="w-100 mb-2"
                    key={`areaSelectButton_${index}`}
                    onClick={() => {
                      handleSelecedArea(area,index);
                    }}
                  >
                    {area.Name}
                  </Button>
                </Col>
                <Col xs={2} className="text-center text-success">
                  {clickedAreas.has(area.Name) && <FaEye onClick={() => handleEyeClick(area.Name)} />}
                </Col>              
                </>
            ))}
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
