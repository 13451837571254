import React from 'react'
import { Modal } from 'react-bootstrap'
import { useClientContext } from '../../../../_context/ClientContext'
import { useCleanerContext } from '../../../../_context/CleanerContext'
import { FaUserCheck } from 'react-icons/fa6'

export const CleanerUpdatedSuccessModal = () => {

    const{setCleanerUpdated,cleanerUpdated} = useCleanerContext()

  return (
    <Modal
    show={cleanerUpdated}
    size="xl"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    onHide={() => setCleanerUpdated(false)}
    >
    
        <Modal.Header  closeButton onHide={() => setCleanerUpdated(false)}>
           <Modal.Title><FaUserCheck/> Success</Modal.Title> 
        </Modal.Header>
        <Modal.Body>
            Cleaner Updated Successfully
        </Modal.Body>
    </Modal>
  )
}
