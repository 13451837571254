import React, {useState} from "react";
import { Button, Col, FormCheck, Row } from "react-bootstrap";
import { FormInput } from "../../../../htmlComponents/FormInput";
import { useCleanerContext } from "../../../../_context/CleanerContext";
import { FaUserPlus } from "react-icons/fa6";
import SuccessfullSubmission from "../../Notifications/SuccessfullSubmission";
import { submitCleaner } from "../../../../_utils/resourceAPI";
import EmptyCleaner from '../../../../_resources/JsonData/EmptyCleaner.json'

interface CleanerTaxABNProps {
  cleanerTFNValidate: (
    value: string,
    isRequired: boolean,
    inputName: string
  ) => string | null;
  cleanerABNValidate: (
    value: string,
    isRequired: boolean,
    inputName: string
  ) => string | null;
}

export const CleanerTaxABN: React.FC<CleanerTaxABNProps> = ({
  cleanerTFNValidate,
  cleanerABNValidate,
}) => {
  const { cleaner, setCleaner } = useCleanerContext();
 

  return (
    <Row>
      
      <Col sm={12}>
        <FormInput
          id="CleanerTFN"
          label="Cleaner TFN"
          type="text"
          value={cleaner.TaxFileNo}
          required={true}
          onChange={(value: string) => setCleaner({ ...cleaner, TaxFileNo: value })}
          validator={cleanerTFNValidate}
        />
      </Col>
      <Col sm={12}>
        <FormInput
          id="CleanerABN"
          label="Cleaner ABN"
          type="text"
          value={cleaner.ABNNo}
          required={true}
          onChange={(value: string) => setCleaner({ ...cleaner, ABNNo: value })}
          validator={cleanerABNValidate}
        />
      </Col>
      <Col sm={12}>
      <FormCheck
  id="CleanerACN"
  label="Does cleaner have an ACN?"
  type="checkbox"
  checked={cleaner.ACN}
  onChange={(event) => setCleaner({ ...cleaner, ACN: event.target.checked })}
/>

        
      </Col>
      
    </Row>
  );
};
