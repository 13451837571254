import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FaCircleInfo } from 'react-icons/fa6'

export const ClientNoConsent = () => {
  return (
    <Container>
        <Row className='pt-2'>
            <Col className='text-danger text-center'>
                <FaCircleInfo/> You are not autorised to create an inspection report without client consent
            </Col>
        </Row>
    </Container>
  )
}
