import React from "react";
import { Button } from "react-bootstrap";
import "./customStyles/custom.css";
import { Link } from "react-router-dom";

interface SquareButtonComponentProps {
  buttonText: string;
  toPage?: string;
  cssClasses?: string;
}

export const SquareButtonComponent: React.FC<SquareButtonComponentProps> = ({
  buttonText,
  toPage,
  cssClasses,
}) => {
  let cssStyles = "squareButton";
  if (cssClasses) {
    cssStyles = cssStyles + " " + cssClasses;
  }
  return toPage ? (
    <Link to={toPage}>
      <Button className={cssStyles}>{buttonText}</Button>
    </Link>
  ) : (
    <Button className={cssStyles}>{buttonText}</Button>
  );
};
