import React, { useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { FormInput } from "../../../../htmlComponents/FormInput";
import { useClientContext } from "../../../../_context/ClientContext";
import { Admin } from "../../../../_models/Admin.model";

interface ClientAdminDetailsProps {
  adminNameValidator: (
    value: string,
    isRequired: boolean,
    inputName: string
  ) => string | null;
  adminEmailValidator: (
    value: string,
    isRequired: boolean,
    inputName: string
  ) => string | null;
  adminContactValidator: (
    value: string,
    isRequired: boolean,
    inputName: string
  ) => string | null;
}

export const ClientAdminDetails: React.FC<ClientAdminDetailsProps> = ({
  adminEmailValidator,
  adminContactValidator,
  adminNameValidator,
}) => {
  const { client, setClient } = useClientContext();
  const [adminToAdd, setAdminToAdd] = useState<Admin>({
    Name: "",
    Email: "",
    Phone: "",
  });

  const isAddAdminValid = () => {
    return (
      !adminEmailValidator(adminToAdd.Email, true, "") &&
      !adminContactValidator(adminToAdd.Phone, false, "") &&
      !adminNameValidator(adminToAdd.Name, true, "")
    );
  };

  const handleAddAdmin = () => {
    setClient((prevClient) => {
      const newAdmins = Array.isArray(prevClient.Admins) ? [...prevClient.Admins] : [];
      return {
        ...prevClient,
        Admins: [...newAdmins, adminToAdd],
      };
    });
    setAdminToAdd({ Name: "", Email: "", Phone: "" });
  };

  const handleRemoveAdmin = (index: number) => {
    setClient((prevClient) => {
      const newAdmins = [...prevClient.Admins];
      newAdmins.splice(index, 1);
      return { ...prevClient, Admins: newAdmins };
    });
  };

  return (
    <Row className="mt-4">
      <Col md={3}>
        <FormInput
          id="AdminName"
          label="Admin Name"
          type="text"
          value={adminToAdd.Name}
          required={true}
          onChange={(value: string) =>
            setAdminToAdd({ ...adminToAdd, Name: value })
          }
          validator={adminNameValidator}
        />
      </Col>
      <Col sm={12} md={3}>
        <FormInput
          id="AdminEmail"
          label="Admin Email"
          type="email"
          value={adminToAdd.Email}
          required={true}
          onChange={(value: string) =>
            setAdminToAdd({ ...adminToAdd, Email: value })
          }
          validator={adminEmailValidator}
        />
      </Col>
      <Col  sm={12}  md={3}>
        <FormInput
          id="AdminPhone"
          label="Admin Contact"
          type="text"
          value={adminToAdd.Phone}
          required={false}
          onChange={(value: string) =>
            setAdminToAdd({ ...adminToAdd, Phone: value })
          }
          validator={adminContactValidator}
        />
      </Col>
      <Col  sm={12}  md={3} className="mt-auto">
        <Button
          className="w-100 btn-warning"
          onClick={handleAddAdmin}
          disabled={!isAddAdminValid()}
        >
          Add Admin
        </Button>
      </Col>
      <Col  sm={12} className="mt-2">
        
        {client.Admins && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Admin Name</th>
                <th>Admin Email</th>
                <th>Admin Contact Number</th>
                <th>Remove Admin</th>
              </tr>
            </thead>
            <tbody>
              {client.Admins.map((admin, index) => (
                <tr key={index}>
                  <td>{admin.Name}</td>
                  <td>{admin.Email}</td>
                  <td>{admin.Phone}</td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => handleRemoveAdmin(index)}
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Col>
   
    </Row>
  );
};
