import React, {useState} from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { FaCircleInfo, FaInfo, FaUserPen, FaUserTie } from 'react-icons/fa6';
import { FormInput } from '../../../../htmlComponents/FormInput';
import { FormSelect } from '../../../../htmlComponents/FormSelect';
import { useUserContext } from '../../../../_context/UserContext';
import { User } from '../../../../_models/User.model';
import { validateEmail, validateName, validatePassword } from "../../../Validations";
import { updateCleaners, updateSystemUsers } from '../../../../_utils/resourceAPI';

interface UpdateUserProps{
    toUpdateUser:User
    onUserUpdate: () => void;
}


export const UpdateUser:React.FC<UpdateUserProps> = ({
    toUpdateUser,
    onUserUpdate
}) => {
    const [showUpdateuserModal, setUpdateuserModal] = useState(false);
    const{userToUpdate,setUserToUpdate} = useUserContext() 
    
    const isFormValid = () => {
      return (
        !validateName(userToUpdate.name, true, "") &&
        !validateEmail(userToUpdate.email, true, "") &&
        !validateName(userToUpdate.username, true, "") &&
        !validatePassword(userToUpdate.password, true, "")
      );
    };

    const handelFormSubmit = () => {
        updateSystemUsers(userToUpdate.id,userToUpdate).then((res) => {
            console.log(res.data);
            console.log("clear User");
            onUserUpdate();
            setUpdateuserModal(false);
          })
          .catch((error) => {
            console.log(error);
          });
    }

  return (
    <>
    <Button className="btn-warning w-100" 
    onClick={() => {
        setUpdateuserModal(true)
        setUserToUpdate(toUpdateUser)
        }}>
      Update User 
    </Button>
    <Modal
      show={showUpdateuserModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setUpdateuserModal(false)}
    >
      <Modal.Header closeButton onHide={() => setUpdateuserModal(false)}>
        <Modal.Title id="contained-modal-title-vcenter">
        <FaUserPen /> Update User 
        </Modal.Title>
        
      </Modal.Header>
      <Modal.Body>
      <Row>
        <Col sm={12} className='mb-2 text-danger'>
           <FaCircleInfo/> This users user role is - {userToUpdate.role}
        </Col>
      <Col sm={12}>
        <FormInput
          id="UsersName"
          label="Name"
          type="text"
          value={userToUpdate.name}
          required={true}
          onChange={(value: string) => setUserToUpdate({...userToUpdate,name:value})}
          validator={validateName}
        />
      </Col>
      <Col sm={12}>
        <FormInput
          id="useremail"
          label="User Email"
          type="email"
          value={userToUpdate.email}
          required={true}
          onChange={(value: string) => setUserToUpdate({...userToUpdate,email:value})}
          validator={validateEmail}
        />
      </Col>
      <Col sm={12}>
      <FormInput
          id="UsersUserName"
          label="Username"
          type="text"
          value={userToUpdate.username}
          required={true}
          onChange={(value: string) => setUserToUpdate({...userToUpdate,username:value})}
          validator={validateName}
        />
      </Col>
      <Col sm={12}>
        <FormInput
          id="userPassowrd"
          label="Users Password"
          type="password"
          value={userToUpdate.password}
          required={true}
          onChange={(value: string) => setUserToUpdate({...userToUpdate,password:value})}
          validator={validatePassword}
        />
      </Col>
      <Col sm={12}>
      
          </Col>
      <Col sm={12} className="mt-1">
        <Button className="w-100 btn-success" onClick={handelFormSubmit} disabled={!isFormValid()}>
          Update User
        </Button>
      </Col>
    </Row>
      </Modal.Body>
    </Modal>
    </>
  )
}
