import React from 'react'
import { SubArea } from '../../../../_models/Report.model'
import { Text } from '@react-pdf/renderer'


interface PDFReportAreaScoringProps{
    SubAreas:SubArea[]
}

export const PDFReportAreaScoring:React.FC<PDFReportAreaScoringProps> = ({SubAreas}) => {
  return (
    <>
    {SubAreas[0].Items[0].Mark === 5 ? <Text style={{color:"green",fontSize:"16px"}}>Satisfied</Text>
 : <Text  style={{color:"red", fontSize:"16px"}}>Need Attention</Text>
}
    </>
  )
}
