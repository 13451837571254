import React, { useEffect } from "react";
import { useUserContext } from "../../_context/UserContext";
import { redirect } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { SquareButtonComponent } from "../../htmlComponents/squareButtonComponent";
import AdminButtons from '../../_resources/Admin/AdminButtons.json'

export const Admin = () => {
  const { isLogged,setCurrentPage } = useUserContext();

  useEffect(() => {
    if (!isLogged) {
      console.log("redirecting user");
      redirect("/");
    }
  }, [isLogged]);


  useEffect(() => {
    setCurrentPage("Admin Options")
  },[])

  return (
    <Container>
      <Row>
      {
            Object.entries(AdminButtons).map(([buttonText, {toPage, key, cssClasses}]) => (
              <Col sm={4} key={key} className="mt-4">
                <SquareButtonComponent
                  buttonText={buttonText}
                  toPage={toPage}
                  key={key}
                  cssClasses={cssClasses}
                />
              </Col>
            ))
          }
      </Row>
    </Container>
  );
};
