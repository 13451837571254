import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  Accordion,
  Modal,
  Alert,
} from "react-bootstrap";
import {
  validateName,
  validateEmail,
  validateAddressLines,
  validatePostCode,
  validateContactNumber,
} from "../../../Validations";
import { ClientBasicDetails } from "../AddClientsComponents/ClientBasicDetails";
import { useClientContext } from "../../../../_context/ClientContext";
import { ClientAddressDetails } from "../AddClientsComponents/ClientAddressDetails";
import { ClientAdminDetails } from "../AddClientsComponents/ClientAdminDetails";
import { useUserContext } from "../../../../_context/UserContext";
import {
  FaClipboardList,
  FaClipboardUser,
  FaUserGear,
  FaUserPen,
  FaUserPlus,
} from "react-icons/fa6";
import { ClientCleanerDetails } from "../AddClientsComponents/ClientCleanerDetails";
import { Client } from "../../../../_models/Client.model";
import { updateClient, deleteClient } from "../../../../_utils/resourceAPI";
import { ClientTypeDetails } from "../AddClientsComponents/ClientTypeDetails";
import { useISOFormattedCurrentDate } from "../../../../_hooks/FormattedDate";
import { ClientReportingDetails } from "../AddClientsComponents/ClientReportingUserDetails";

interface UpdateClinetProps {
  clientToUpdate: Client;
  onClientUpdated: () => void;
  onClientDeleted: () => void;
}

export const UpdateClient: React.FC<UpdateClinetProps> = ({
  clientToUpdate,
  onClientUpdated,
  onClientDeleted,
}) => {
  const { setCurrentPage } = useUserContext();
  const [showUpdateClientModal, setUpdateClientModalShow] = useState(false);
  const { setClient, client } = useClientContext();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showExpireConfirmationModal, setShowExpireConfirmationModal] =
    useState(false);
  const [showActiveConfirmationModal, setShowActiveConfirmationModal] =
    useState(false);

  const handleDeleteClient = () => {
    deleteClient(client.Id)
      .then((response) => {
        onClientDeleted();
        setShowConfirmationModal(false);
        setUpdateClientModalShow(false);
      })
      .catch((error) => {
        console.error("Error deleting client:", error);
        // Optionally, handle the error here, such as showing a notification to the user
      });
  };

  const isFormValid = () => {
    return (
      !validateName(client.Name, true) &&
      !validateEmail(client.Email, true) &&
      !validateAddressLines(client.Address.Line1, true) &&
      !validateAddressLines(client.Address.Line2, false) &&
      !validatePostCode(client.Address.Postcode.toString(), true)
    );
  };

  useEffect(() => {
    setCurrentPage(`Update Client`);
  }, []);

  const handleFormSubmit = () => {
    updateClient(client.Id, client)
      .then((res) => {
        onClientUpdated();
        setUpdateClientModalShow(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log(client)
  },[client])

  const ISOFormattedCurrentDate = useISOFormattedCurrentDate();

  const handleExpireClient = () => {
    // Create an updated client object directly
    const updatedClient = {
      ...client,
      IsExpired: true,
      ExpiredDate: ISOFormattedCurrentDate,
    };
  
    // Update the client state
    setClient(updatedClient);
    setShowExpireConfirmationModal(false);
  
    // Use the updated client object for the API call
    updateClient(client.Id, updatedClient)
      .then((res) => {
        onClientUpdated();
        setUpdateClientModalShow(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  

  const handleActiveClient = () => {
    // Create an updated client object directly
    const updatedClient = {
      ...client,
      IsExpired: false,
    };
  
    // Update the client state
    setClient(updatedClient);
    setShowActiveConfirmationModal(false);
  
    // Use the updated client object for the API call
    updateClient(client.Id, updatedClient)
      .then((res) => {
        onClientUpdated();
        setUpdateClientModalShow(false);
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  return (
    <>
      <Button
        onClick={() => {
          setUpdateClientModalShow(true);
          setClient(clientToUpdate);
        }}
        variant={clientToUpdate.IsExpired ? "danger" : "primary"}
      >
        Update Client
      </Button>
      <Modal
        show={showUpdateClientModal}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setUpdateClientModalShow(false)}
      >
        <Modal.Header
          closeButton
          onHide={() => setUpdateClientModalShow(false)}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            <FaUserPen /> Update Client
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="p-4">
            <Alert variant={client.IsExpired ? "warning" : "success"}>
              {client.IsExpired ? "This client is Expired" : "Active Client"}
            </Alert>
            <Form>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h5>
                      <FaClipboardList /> Client Contact Infomation
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col sm={12}>
                        <ClientBasicDetails
                          clientNameValidator={validateName}
                          clientemailValidator={validateEmail}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}></Col>
                      <Col sm={6}>
                        <ClientAddressDetails
                          addressValidator={validateAddressLines}
                          postCodeValidator={validatePostCode}
                        />
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <h5>
                      <FaUserGear /> Select Client Type
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ClientTypeDetails />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <h5>
                      <FaClipboardUser /> Client Admin Infomation
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <ClientAdminDetails
                        adminEmailValidator={validateEmail}
                        adminContactValidator={validateContactNumber}
                        adminNameValidator={validateName}
                      />
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <h5>
                      <FaUserPlus /> Assigned Cleaner Infomation
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <ClientCleanerDetails />
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <h5>
                      <FaUserPen /> Supervisor Details
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <ClientReportingDetails />
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Row>
                <Col sm={2}>
                  <Button
                    variant="danger"
                    className="w-100 mt-2"
                    onClick={() => {
                      setShowConfirmationModal(true);
                    }}
                  >
                    Delete Client
                  </Button>
                </Col>
                <Col sm={3}>
                  {!client.IsExpired && (
                    <Button
                      className="btn-warning w-100 mt-2"
                      onClick={() => {
                        setShowExpireConfirmationModal(true);
                      }}
                    >
                      Expire Client
                    </Button>
                  )}
                  {client.IsExpired && (
                    <Button
                      className="btn-warning w-100 mt-2"
                      onClick={() => {
                        setShowActiveConfirmationModal(true);
                      }}
                    >
                      Activate Client
                    </Button>
                  )}
                </Col>
                <Col className="">
                  <Button
                    className="btn-success w-100 mt-2"
                    onClick={handleFormSubmit}
                    disabled={!isFormValid()}
                  >
                    Update Client
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
      <Modal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure that you want to delete this client?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmationModal(false)}
          >
            No
          </Button>
          <Button variant="danger" onClick={handleDeleteClient}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showExpireConfirmationModal}
        onHide={() => setShowExpireConfirmationModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Expire Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure that you want to expire this client?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowExpireConfirmationModal(false)}
          >
            No
          </Button>
          <Button variant="danger" onClick={handleExpireClient}>
            Yes, Expire
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showActiveConfirmationModal}
        onHide={() => setShowActiveConfirmationModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Activate Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure that you want to active this client again?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowActiveConfirmationModal(false)}
          >
            No
          </Button>
          <Button variant="danger" onClick={handleActiveClient}>
            Yes, This client is not expired anymore
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
