import React,{useState} from 'react'
import { Area } from '../../../../_models/Report.model'
import { Button, ButtonGroup, Col, Row } from 'react-bootstrap'
import { ClientSubAreas } from './ClientSubAreas'
import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa6'
import { useReportContext } from '../../../../_context/ReportContext'
import { FaArrowRight } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

interface ClientAreasProps{
    selectedAreaIndex:number
}

export const ClientAreas:React.FC<ClientAreasProps> = ({
    selectedAreaIndex
}) => {

    const {newReport} = useReportContext()
    const navigate = useNavigate()

    const handlePageSubmit = () => {
        const allAreasScored = true ;
        if (allAreasScored) {
          console.log("All areas have been scored, submitting the report...");
          navigate('/reports/create_new_report/comments')
        } else {
          console.log("Not all areas are scored"); // Add this line
          window.scrollTo(0, 0);  // Scroll to the top of the page
        }
      };

  return (
    <Row className='mt-2'>
        <Col sm={12}>
            <span className='generalTitle'>
                {newReport.Areas[selectedAreaIndex].Name}
            </span>
        </Col>
            <Col sm={12}>
            <ClientSubAreas subAreas={newReport.Areas[selectedAreaIndex].SubAreas} selectedAreaIndex={selectedAreaIndex} />
            </Col>
            <Row className='mt-2'>
            <Col sm={8}></Col>
            <Col sm={4}>
            <Button className="w-100 mb-4" variant="warning" onClick={handlePageSubmit}>Move to client comments <FaArrowRight/> </Button>
                        </Col>
            </Row>
            

    </Row>
  )
}
