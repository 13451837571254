import { Address } from "./Address.model";
import {Admin} from "./Admin.model"

export interface Client {
        Name: string;
        Id: string;
        Address: Address;
        Email: string;
        CreateDate: string;
        ExpiredDate: string;
        IsExpired: boolean;
        Type:ClientType;
        CleanerIds:string[];
        Admins:Admin[];
        ReportingUserIds:string[];
} 




export enum ClientType {
        School = 0,
        Kindergarden = 1,
        Domestic = 2,
        Commercial = 4,
        MCH = 5,
        AirBNB = 3,
      }