import React from "react";
import { Alert } from "react-bootstrap";

interface CreateReportAlertProps {
  alertMessage: string;
  displayAlert: boolean;
}

export const CreateReportAlerts: React.FC<CreateReportAlertProps> = ({
  alertMessage,
  displayAlert,
}) => {
  return <>{displayAlert && <Alert variant="danger">{alertMessage}</Alert>}</>;
};
