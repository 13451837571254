import React, { useEffect, useState } from "react";
import {
    Alert,
  Button,
  ButtonGroup,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import { useReportContext } from "../../../../_context/ReportContext";
import { ClientSubAreas } from "./ClientSubAreas";
import { FaArrowRight, FaThumbsDown, FaThumbsUp } from "react-icons/fa6";
import { Area } from "../../../../_models/Report.model";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../../_context/UserContext";

export const ClientAreaBasedScoringView = () => {
  const { newReport, setNewReport,areaBasedScoringAreabuttonsClicked,setAreaBasedScoringAreabuttonsClicked,isNewReport,setIsNewReport } = useReportContext();

  const [errorMessage, setErrorMessage] = useState("");
  const {loggedUser} = useUserContext()

  const handleAreaItemScores = (area: Area, score: number): Area => {
    const updatedArea = { ...area };
    updatedArea.SubAreas = area.SubAreas.map((subArea) => ({
      ...subArea,
      Items: subArea.Items.map((item) => ({ ...item, Mark: score })),
    }));
    return updatedArea;
  };

  useEffect(() => {
    setNewReport({...newReport,InspectedBy:loggedUser!.data.Name})
  },[])

  useEffect(() => {
    // Reset button clicks state when a new report is selected
    if (newReport && isNewReport) {
      setAreaBasedScoringAreabuttonsClicked({});
    }
  }, [newReport]);


  
  const navigate = useNavigate()
  
  const handleButtonClick = (areaIndex: number, buttonName: string) => {
    setAreaBasedScoringAreabuttonsClicked((prev) => ({
      ...prev,
      [areaIndex]: prev[areaIndex] === buttonName ? null : buttonName,
    }));

    setIsNewReport(false)

    setNewReport((prevReport) => {
      const updatedReport = { ...prevReport };
      updatedReport.Areas = prevReport.Areas.map((area, index) => {
        if (index === areaIndex) {
          return handleAreaItemScores(area, buttonName === "up" ? 5 : 1);
        }
        return area;
      });
      return updatedReport;
    });
  };

  const handlePageSubmit = () => {
    const allAreasScored = Object.keys(areaBasedScoringAreabuttonsClicked).length === newReport.Areas.length ;
    if (allAreasScored) {
      setErrorMessage("");
      console.log("All areas have been scored, submitting the report...");
      navigate('/reports/create_new_report/comments')
    } else {
      console.log("Not all areas are scored"); // Add this line
      setErrorMessage("All areas need to be scored before submitting!");
      window.scrollTo(0, 0);  // Scroll to the top of the page
    }
  };
  
  return (
    <Container>
        {errorMessage && (<Alert variant="danger">{errorMessage}</Alert>)}
      {newReport.Areas.map((area, index) => (
        <Row className="mb-2">
          <Col sm={12}>
            <span className="generalTitle" key={`areaIndex_${index}`}>
              {area.Name}
            </span>
          </Col>

          <Col sm={8}>
            <ClientSubAreas subAreas={area.SubAreas} />
          </Col>
          <Col>
            <ButtonGroup className="w-100 height100px mt-4">
              <Button
                variant={
                    areaBasedScoringAreabuttonsClicked[index] === "up" ? "success" : "outline-success"
                }
                onClick={() => handleButtonClick(index, "up")}
              >
                <FaThumbsUp size={30} />
              </Button>
              <Button
                variant={
                    areaBasedScoringAreabuttonsClicked[index] === "down" ? "danger" : "outline-danger"
                }
                onClick={() => handleButtonClick(index, "down")}
              >
                <FaThumbsDown size={30} />
              </Button>
            </ButtonGroup>
          </Col>
          <hr className="mt-4" />
        </Row>
      ))}
      <Button className="w-100 mb-4" variant="warning" onClick={handlePageSubmit}>Move to client comments <FaArrowRight/> </Button>
    </Container>
  );
};
