import { View, Text } from "@react-pdf/renderer";
import React from "react";
import { styles } from "./PDFReportCSS";
import { FaultReport } from "../../../_models/Report.model";
import { Client } from "../../../_models/Client.model";

interface PDFReportRemarksPageProps {
  ClientRemarks: string;
  CNARemarks: string;
  FaultReports: FaultReport[];
  client:Client
}

export const PDFReportRemarks: React.FC<PDFReportRemarksPageProps> = ({
  ClientRemarks,
  CNARemarks,
  FaultReports,
  client
}) => {

  const area_based_scoring_types =
  process.env.REACT_APP_AREA_BASED_SCORING_TYPES?.split(",");
  const hideSectionForDomesticReports = !area_based_scoring_types?.includes(client.Type.toString())
  return (
    <>
      <View key={"remarksSection"} wrap={false}>
        <View style={{ ...styles.row, marginTop: "20px" }}>
        {hideSectionForDomesticReports && 
          <View style={{ ...styles.col, width: "100%" }}>
            <Text style={{...styles.secondaryTitles, marginBottom:"10px"}}>Fault Report</Text>
            <View
              style={{
                ...styles.table,
                marginRight: "10px",
                marginLeft: "10px",
              }}
            >
              {FaultReports.map((faultReportItem, index) => (
                <>
                  {faultReportItem.Item &&
                  <View
                  key={`${faultReportItem.Item}item${index}`}
                  style={styles.tableRow}
                >
                  <View
                    style={{
                      ...styles.tableCol,
                      width: "40%",
                    }}
                  >
                    <Text style={styles.tableCell}>
                      {faultReportItem.Item}
                    </Text>
                  </View>
                  <View
                    style={{
                      ...styles.tableCol,
                      width: "60%",
                    }}
                  >
                    <Text style={styles.tableCell}>
                      {faultReportItem.Comment}
                    </Text>
                  </View>
                </View>
                
                }
                                  {faultReportItem.Item.length === 0 ?             <Text style={{ ...styles.paragapthText, margin: "10px" }}>
No Falult Items added</Text> : <></> }

                </>
              ))}
              
            </View>
          </View> }
        </View>
        <View style={{ ...styles.row, marginTop: "20px" }}>
          <View style={{ ...styles.col, width: "100%" }}>
            <Text style={{...styles.secondaryTitles,color:"#000"}}>Client Remarks</Text>
            <Text style={{ ...styles.paragapthText, marginTop: "10px" }}>
              {ClientRemarks}
            </Text>
          </View>
        </View>
        <View style={{ ...styles.row, marginTop: "10px" }}>
          <View style={{ ...styles.col, width: "100%" }}>
            <Text style={{...styles.secondaryTitles,color:"#000"}}>CNA Remarks</Text>
            <Text style={{ ...styles.paragapthText, marginTop: "10px" }}>
              {CNARemarks}
            </Text>
          </View>
        </View>
      </View>
    </>
  );
};

