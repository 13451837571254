import React, { useEffect, useState } from "react";
import { Cleaner } from "../../../../_models/Cleaner.model";
import { getAllCleaners } from "../../../../_utils/resourceAPI";
import { useClientContext } from "../../../../_context/ClientContext";
import { Button, Col, Row, Table } from "react-bootstrap";
import { FormSelect } from "../../../../htmlComponents/FormSelect";
import { mandatorySelectorValidator } from "../../../Validations";

export const ClientCleanerDetails = () => {
  const [allCleaners, setAllCleaners] = useState<Cleaner[]>([]);
  const [availableCleaners, setAvailableCleaners] = useState<Cleaner[]>([]);
  const { client, setClient } = useClientContext();
  const [selectedCleaner, setSelectedCleaner] = useState<string>("");

  const fetchCleaners = () => {
    getAllCleaners()
      .then((res) => {
        const allCleaners: Cleaner[] = res.data;
        setAllCleaners(allCleaners)
        const availableCleaners = allCleaners.filter(
          (cleaner) => !client.CleanerIds.includes(cleaner.Id)
        );
        setAvailableCleaners(availableCleaners)
      })
      .catch((error) => {
        console.log("Failed to fetch cleaners");
      });
  };

  useEffect(() => {
    fetchCleaners();
  }, []);

  const cleanersToOptions = (cleanersArray: Cleaner[]): Record<string, string> => {
    return cleanersArray.reduce<Record<string, string>>((acc, cleaner) => {
      acc[`${cleaner.Name} - ${cleaner.Phone} - ${cleaner.Email}`] = cleaner.Id;
      return acc;
    }, {});
  };

  const cleanerOptions = cleanersToOptions(availableCleaners);

  const handleAddCleaner = () => {
    // Add the selected cleaner ID to the client's cleaners
    setClient((prevClient) => ({
      ...prevClient,
      CleanerIds: [...prevClient.CleanerIds, selectedCleaner],
    }));
    // Remove the added cleaner from the available cleaners list
    setAvailableCleaners((prevAvilableCleaners) =>
    prevAvilableCleaners.filter((cleaner) => cleaner.Id !== selectedCleaner)
    );
    setSelectedCleaner("");
  };

  const handleRemoveCleaner = (cleaner: Cleaner) => {
    // Remove the cleaner ID from the client's cleaners
    setClient((prevClient) => ({
      ...prevClient,
      CleanerIds: prevClient.CleanerIds.filter((id) => id !== cleaner.Id),
    }));
    // Add the removed cleaner back to the available cleaners list
    setAvailableCleaners((prevAvilableCleaners) => [...prevAvilableCleaners, cleaner]);
  };

  return (
    <Row>
      <Col sm={10}>
        <FormSelect
          id="CleanerSelector"
          label="Select Cleaner"
          optionAndValue={cleanerOptions}
          key={"cleanerSelector"}
          onChange={setSelectedCleaner}
          required={true}
          value={selectedCleaner}
          validator={mandatorySelectorValidator}
        />
      </Col>
      <Col sm={2} className="mt-auto">
        <Button className="w-100 btn-warning" onClick={handleAddCleaner}>
          Add Cleaner
        </Button>
      </Col>
      <Col sm={12}>
        <Table striped bordered hover className="mt-4">
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {client.CleanerIds.map((id) => {
              const cleaner = allCleaners.find((c) => c.Id === id);
              return cleaner ? (
                <tr key={id}>
                  <td>{cleaner.Name}</td>
                  <td>{cleaner.Phone}</td>
                  <td>{cleaner.Email}</td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => handleRemoveCleaner(cleaner)}
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              ) : null;
            })}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};
