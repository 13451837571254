import React, { useEffect, useState } from 'react'
import { getAllSystemUsers } from '../../../../_utils/resourceAPI';
import { User } from '../../../../_models/User.model';
import { Col, FormControl, Row, Table } from 'react-bootstrap';
import { FaUserPlus } from 'react-icons/fa6';
import { UpdateUser } from '../UpdateUsers/UpdateUsers';
import { useUserContext } from '../../../../_context/UserContext';
import { UserUpdatedSuccessModal } from '../UpdateUsers/UserUpdatedSuccessModal';

export const AddedUsers = () => {

    const [allAddedSysUsers, setAllAddedSysUsers] = useState<User[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const {setUserUpdated} = useUserContext()

    const fetchSysUsers
     = () => {
        getAllSystemUsers()
          .then((res) => {
            const allUsers: User[] = res.data;
            setAllAddedSysUsers(allUsers);
          })
          .catch((error) => {
            console.log("Failed to fetch users");
          });
      };

      useEffect(() => {
        fetchSysUsers()
      },[])

      const filteredUsers = allAddedSysUsers.filter(
        (user) =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.role.toLowerCase().includes(searchTerm.toLowerCase())
      );

      const onUserUpdate = () => {
        fetchSysUsers();
        setUserUpdated(true)
      };


  return (
    <Row>
          <Col sm={12}>
        <h5>
          {" "}
          <FaUserPlus /> Add a New User{" "}
        </h5>
        <hr />
      </Col>
      <Col sm={12}>
      <FormControl
          type="text"
          placeholder="Search by name, username, email or role"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="mb-1"
        />
      </Col>
      <Col sm={12}>
    <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => {
              return user ? (
                <tr key={user.id}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td><UpdateUser 
                 toUpdateUser={user}
                 onUserUpdate={onUserUpdate}
                 key={user.id}
                  /></td>
                </tr>
              ) : null;
            })}
          </tbody>
        </Table>
        </Col>
            <UserUpdatedSuccessModal/>
    </Row>
  )
}
