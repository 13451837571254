import React from 'react'
import { Report } from '../_models/Report.model'
import { Button, ButtonGroup, Table } from 'react-bootstrap'
import { FaCircleInfo } from 'react-icons/fa6'


interface ReportsTableProps{
    reports:Report[]
    noResultsMessage:string
    viewReportHandler: (report:Report) => void
}

export const ReportsTable:React.FC<ReportsTableProps> = ({
    reports,noResultsMessage,viewReportHandler
}) => {
  return (
    <>
    {reports.length > 0 ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Created Date</th>
              <th>Score</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report) => (
              <tr key={report.Id}>
                <td>{report.ReportedDate}</td>
                <td>{report.TotalScore}</td>
                <td>
                  <ButtonGroup className="w-100">
                    <Button
                      onClick={() => {
                        viewReportHandler(report);
                      }}
                      variant="success"
                    >
                      View Report
                    </Button>
                    <Button variant="warning" disabled>Update Report</Button>
                  </ButtonGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p className="text-seconday">
          <FaCircleInfo /> {noResultsMessage}
        </p>
      )}
    </>
  )
}
