import React from 'react'
import { Modal } from 'react-bootstrap'
import { useClientContext } from '../../../../_context/ClientContext'

export const AddClientSuccessModal = () => {

    const{clientAdded,setClientAdded} = useClientContext()

  return (
    <Modal
    show={clientAdded}
    size="xl"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    onHide={() => setClientAdded(false)}
    >
    
        <Modal.Header  closeButton onHide={() => setClientAdded(false)}>
           <Modal.Title>Success</Modal.Title> 
        </Modal.Header>
        <Modal.Body>
            Client Added Successfully
        </Modal.Body>
    </Modal>
  )
}
