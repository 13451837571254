import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./ReportTemplate.css";

import { useReportContext } from "../../../../_context/ReportContext";
import {
  ReportHeader,
} from "./ReportTemplateComponents/ReportHeader";
import { ReportAreaView } from "./ReportTemplateComponents/ReportAreaView";
import { ReportFalultReport } from "./ReportTemplateComponents/ReportFalultReport";
import { ReportRemarksView } from "./ReportTemplateComponents/ReportRemarksView";
import { ReportConfirmationView } from "./ReportTemplateComponents/ReportConfirmationView";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PDFReport } from "../../ReportToPDF/PDFReport";
import EmailSenderCleaners from "../../../../_aws/EmailSenderCleaners";
import EmailSenderClient from "../../../../_aws/EmailSenderClients";
import { saveReport } from "../../../../_utils/resourceAPI";
import SubmitReportSuccess from "../NotificationModals/SubmitReportSuccess";
import { useLocation } from "react-router-dom";

export const ReportViewLayout = () => {
  const { currentViewingReport } = useReportContext();
  const area_based_scoring_types =
  process.env.REACT_APP_AREA_BASED_SCORING_TYPES?.split(",");
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleShowSuccessModal = () => setShowSuccessModal(true);
  const [enableEmailButtons,setEnableEmailButtons] = useState(false)
  const location = useLocation();

  const handleSubmit = async () => {
    try {
      const response = await saveReport(currentViewingReport);
      setEnableEmailButtons(true)
      handleShowSuccessModal();
    } catch (error) {
      // Handle error in submission, e.g., show an error message
      console.error(error);
    }
  };

  const isInViewReports = location.pathname.includes(
    "reports/client_reports"
  );
  
  return (
    <Container fluid className="mt-4 mb-4">
      <Container>
        {currentViewingReport.Id}
        <ReportHeader client={currentViewingReport.Client} reportCreateDate={currentViewingReport.ReportedDate.toString()} totalScore={currentViewingReport.TotalScore.toString()}  key="reportHeader" />
        <ReportAreaView Areas={currentViewingReport.Areas} key="areaView"/>           
            {area_based_scoring_types?.includes(
            currentViewingReport.Client.Type.toString()) ? <></> : <ReportFalultReport FaultReports={currentViewingReport.FaultReport} /> }
        <ReportRemarksView ClientRemarks={currentViewingReport.ClientRemarks} CNARemarks={currentViewingReport.CNARemarks}   />
        <ReportConfirmationView CNASignature={currentViewingReport.QASignature} ClientSignature={currentViewingReport.ClientSignature} ClientConsent={currentViewingReport.ClientConcent}/>
      </Container>
      <hr/>
      <Row>
        <Col sm={4}>
          <Container>
        <Row>
        <Col>

        {!isInViewReports && <Col sm={12}>
           <Button variant="success" className="w-100" onClick={handleSubmit}>
            Save Report
          </Button>
          </Col>}
          </Col></Row>
          </Container>
          <Col sm={12} className="mb-2">
          <EmailSenderCleaners pdfBlob={pdfBlob} isEnabled={enableEmailButtons || isInViewReports}/>
          </Col>
          <Col sm={12} className="mb-2">
          <EmailSenderClient pdfBlob={pdfBlob} isEnabled={enableEmailButtons || isInViewReports}/>
          </Col>
        </Col>
        <Col sm={4}>
        </Col>
        <Col sm={4}>
        <Col sm={12} className="mb-2 text-center">
        <PDFDownloadLink document={<PDFReport currentViewingReport={currentViewingReport}/>} fileName={`${currentViewingReport.Client.Name}-${currentViewingReport.ReportedDate}.pdf`}>
      {({ blob, url, loading, error }) => {
        if (blob && !loading && !error) {
          setPdfBlob(blob);
        }
        return loading ? 'Loading document...' : 'Download PDF';
      }}
    </PDFDownloadLink>
    </Col>
        </Col>
      </Row>
      <SubmitReportSuccess
        show={showSuccessModal}
        onHide={handleCloseSuccessModal}
        successMessage="Report submitted successfully"
      />
    </Container>
  );
};
