import React,{useEffect, useState} from 'react'
import { useReportContext } from '../../../../_context/ReportContext'
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';
import { SignaturePadComponent } from '../../../../htmlComponents/signaturePadComponent';
import { useNavigate } from 'react-router-dom';
import SignatureSavedModal from './CreateReportAlertModals/SignatureSavedConfirmModal';
import { ReportViewLayout } from '../../ViewReports/ReportTemplate/ReportViewLayout';
import { ReportViewModal } from '../../ViewReports/ReportTemplate/ReportViewModal';
import { useUserContext } from '../../../../_context/UserContext';

export const ClientAndCNASignatures = () => {
  const {setNewReport,newReport,setCurrentViewingReport} = useReportContext()
  const [clientSignature, setClientSignature] = useState<string>('');
  const [qaSignature, setQaSignature] = useState<string>('');
  const [errorMessage,setErrorMessage] = useState<string>("")
  const [showSignatureSavedModal, setShowSignatureSavedModal] = useState(false);
  const [viewReport, setViewReport] = useState<boolean>(false);

  // const navigate = useNavigate()

  const saveClientSignature = (base64: string) => {
    setClientSignature(base64);
    setShowSignatureSavedModal(true)
  };

  const saveQaSignature = (base64: string) => {
    setQaSignature(base64);
    setShowSignatureSavedModal(true)
  };

  const handleCloseModal = () => setShowSignatureSavedModal(false);


  useEffect(() => {
    if (clientSignature && qaSignature) {
      setNewReport({
        ...newReport,
        ClientSignature: clientSignature,
        QASignature: qaSignature
      });
    }
  }, [clientSignature, qaSignature]);


  const handlePageSubmit = () => {
    if( ( newReport.ClientSignature === "") || newReport.QASignature === ""){
      setErrorMessage("Please make sure you have saved/taken both the signatures")
       window.scrollTo(0, 0);}
       else{
        setErrorMessage("")
        // navigate('/reports/create_new_report/reviewReport')
        setViewReport(true);
        setCurrentViewingReport(newReport)
       }

    
  }

  return (
    <Container>
      {errorMessage && (<Alert variant="danger">{errorMessage}</Alert>)}
      <Row>
        <Col sm={6}>
          <h5>Client Signature</h5>
          <SignaturePadComponent saveSignature={saveClientSignature} />
        </Col>
      
        <Col sm={6}>
          <h5>QA Signature</h5>
          <SignaturePadComponent saveSignature={saveQaSignature} />
        </Col>
      </Row>
      <Button onClick={handlePageSubmit} className='w-100' variant='warning'>Review Report</Button> 
      <SignatureSavedModal show={showSignatureSavedModal} handleClose={handleCloseModal}/>
      <ReportViewModal viewReport={viewReport} setViewReport={setViewReport} />

    </Container>
  )
}
