import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { FormInput } from '../../../../htmlComponents/FormInput'
import { useClientContext } from '../../../../_context/ClientContext'


interface ClientBasicDataProps{
    clientNameValidator?: (value: string,isRequired:boolean,inputName:string) => string | null
    clientemailValidator?: (value: string,isRequired:boolean,inputName:string) => string | null
}

export const ClientBasicDetails:React.FC<ClientBasicDataProps> = ({
    clientNameValidator,
    clientemailValidator
}) => {
  
  const { client, setClient } = useClientContext();
  return (
    <Row>
    <Col sm={6}>
    <FormInput
      id="ClientName"
      label="Client Name"
      type="text"
      value={client.Name}
      required={true}
      onChange={(value: string) => setClient({ ...client, Name: value })}
      validator={clientNameValidator}
    />
    </Col><Col sm={6}>
    <FormInput
      id="ClientEmail"
      label="Client Email"
      type="email"
      required
      value={client.Email}
      onChange={(value: string) => setClient({ ...client, Email: value })}
      validator={clientemailValidator}
    />
</Col>
</Row>
  )
}
