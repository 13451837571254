import React, { useEffect } from 'react'
import {
  validateName,
  validateEmail,
  mandatorySelectorValidator,
  validatePassword
} from "../../Validations";
import { Col, Container, Row } from 'react-bootstrap';
import { UserBasicDetails } from './AddUserComponents/UserBasicDetails';
import { useUserContext } from '../../../_context/UserContext';
import { AddedUsers } from './AddUserComponents/AddedUsers';


export const AddUsers = () => {

  

  const{setCurrentPage} = useUserContext()
   useEffect(() => {
    setCurrentPage(`Add New System Users`);
  },[])

  return (
    <Container className="p-4">
      <Row>
        <Col sm={5}>
          <UserBasicDetails
            nameValidator={validateName}
            userNameValidator={validateName}
            emailValidator={validateEmail}
            passwordValidator={validatePassword}
            selectValidator={mandatorySelectorValidator}
          />
        </Col>
        <Col sm={7}>
          <AddedUsers/>
        </Col>
      </Row>
    </Container> 
    )
}
