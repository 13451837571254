import React from 'react'
import { SubArea } from '../../../../../../_models/Report.model'
import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa6'
import { Col } from 'react-bootstrap'

interface DomesticReportScoreOutputProps{
    SubAreas:SubArea[]
}

export const DomesticReportScoreOutput:React.FC<DomesticReportScoreOutputProps> = ({
    SubAreas
}) => {
  return (
    <Col sm={12} className='mt-4'>{SubAreas[0].Items[0].Mark === 5 ? <span className='text-success'><FaThumbsUp/> <strong>Satisfied</strong></span>: <span className='text-danger text-center'><FaThumbsDown/> <strong>Need Attention</strong></span>}</Col>

  )
}
