import React, { useEffect, useState } from "react";
import { Button, Col, FormControl, Row } from "react-bootstrap";
import { Client, ClientType } from "../_models/Client.model";
import { useClientContext } from "../_context/ClientContext";
import { FaClipboardCheck } from "react-icons/fa6";
import "../htmlComponents/customStyles/clientFilterStyles.css";
import { getAllClients } from "../_utils/resourceAPI";
import { useUserContext } from "../_context/UserContext";
import { useLocation } from "react-router-dom";

interface clientSearchAndFilterProps {
  setFiltererdClients: React.Dispatch<React.SetStateAction<Client[]>>;
  setDataStatus: React.Dispatch<React.SetStateAction<string>>;
}

export const ClientSearchAndFilter: React.FC<clientSearchAndFilterProps> = ({
  setFiltererdClients,
  setDataStatus,
}) => {
  const { allClients, setAllClients } = useClientContext();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedType, setSelectedType] = useState<ClientType | "all">("all");
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const {loggedUser} = useUserContext();

  const fetchClients = () => {
    setIsLoading(true);
    getAllClients()
      .then((res) => {
        const allClients: Client[] = res.data;
        setAllClients(allClients);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Failed to fetch cleaners");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setDataStatus("Loading Clients");
    fetchClients();
  }, []);

  useEffect(() => {
    // const clientsForUser = allClients.filter(client => client.ReportingUserIds.includes())
    const shouldFilterByType = location.pathname.includes(
      "select_client_for_new_report"
    );


    
    const shouldFilterByTypeViewReport = location.pathname.includes(
      "reports/view_reports"
    );

    
    const isAdminView = location.pathname.includes(
      "admin"
    );


    const isRelevantUser = loggedUser?.data.Name.toLowerCase().includes("admin") // || 
                       //loggedUser?.data.Name.toLowerCase().includes("ashan");

const filtered = allClients.filter(client => {
    const isTypeMatch = selectedType === "all" || client.Type === selectedType;
    const shouldIncludeByType = shouldFilterByType || (isRelevantUser && (shouldFilterByTypeViewReport || isAdminView)) || client.Type === ClientType.Domestic;
    const isUserAuthorized = isAdminView || isRelevantUser || (loggedUser ? client.ReportingUserIds.includes(loggedUser.data.Id) : false);
    const isSearchMatch = client.Name.toLowerCase().includes(searchTerm.toLowerCase()) || client.Email.toLowerCase().includes(searchTerm.toLowerCase());

    return isTypeMatch && isUserAuthorized && isSearchMatch;
});


    setFiltererdClients(filtered);
    if (!isLoading && !filtered.length) {
      setDataStatus("No clients found");
    }
  }, [allClients, searchTerm, selectedType, isLoading]);

  const clientTypeButtons = Object.values(ClientType)
    .filter((value) => typeof value === "string")
    .map((type, index) => (
      <Button
        key={type}
        onClick={() =>
          setSelectedType(ClientType[type as keyof typeof ClientType])
        }
        variant={
          selectedType === ClientType[type as keyof typeof ClientType]
            ? "success"
            : "warning"
        }
        className="clientFilterButton"
      >
        {type}
      </Button>
    ));

  // "All" button to reset filter
  const allButton = (
    <Button
      key="all"
      onClick={() => setSelectedType("all")}
      variant={selectedType === "all" ? "success" : "warning"}
      className="clientFilterButton"
    >
      All
    </Button>
  );

  return (
    <Row className="">
      <Col sm={12}>
        <h5>
          <FaClipboardCheck /> CNA Client List {loggedUser?.data.Name.toLowerCase()}
        </h5>
        <hr />
      </Col>
      <Col sm={12}>
        <Row className="mb-4">
          <Col sm={12} className="clientFilterButtonsContainer">
            {allButton}
            {clientTypeButtons}
          </Col>
          <Col sm={12}>
            <FormControl
              type="text"
              placeholder="Search by name, phone or email..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="mb-1"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
