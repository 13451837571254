import { emailValidatorRegex, stringValidatorRegex, addressValidatorRegex, postCodeValidatorRegex, contactNumberValidateRegex,passwordValidatorRegex, tfnValidatorRegex, abnValidatorRegex } from "./Constrains";

const validator = (
  value: string,
  regex: RegExp,
  isRequired: boolean,
  inputField?: string
) => {
  const inputFieldInLowerCase = inputField?.toLowerCase();
  if (value?.trim() === "" && isRequired) {
    return `${inputField} is required.`;
  } else if (!regex.test(value) && value?.trim() !== "") {
    return `Please add a valid ${inputFieldInLowerCase}`;
  } else {
    return null;
  }
};

export const validateName = (
  value: string,
  isRequired: boolean,
  inputField?: string
) => {
  return validator(value, stringValidatorRegex, isRequired, inputField);
};

export const validateEmail = (
  value: string,
  isRequired: boolean,
  inputField?: string
) => {
  return validator(value, emailValidatorRegex, isRequired, inputField);
};

export const validateAddressLines = (
  value: string,
  isRequired: boolean,
  inputField?: string
) => {
  return validator(value, addressValidatorRegex, isRequired, inputField);
};

export const validatePostCode = (
  value: string,
  isRequired: boolean,
  inputField?: string
) => {
  return validator(value, postCodeValidatorRegex, isRequired, inputField);
};

export const validateContactNumber = (
  value: string,
  isRequired: boolean,
  inputField?: string
) => {
  return validator(value, contactNumberValidateRegex, isRequired, inputField);
};


export const validateTFN = (
  value: string,
  isRequired: boolean,
  inputField?: string
) => {
  return validator(value, tfnValidatorRegex, isRequired, inputField);
};


export const validateABN = (
  value: string,
  isRequired: boolean,
  inputField?: string
) => {
  return validator(value, abnValidatorRegex, isRequired, inputField);
};


export const mandatorySelectorValidator = (
  value: string,
  isRequired: boolean,
  selectorLabel?: string
) => {
  if (value?.trim() === "" && isRequired) {
    return `${selectorLabel} is required.`;
  } else {
    return null;
  }
};


export const validatePassword = (
  value: string,
  isRequired: boolean,
  inputField?: string
) => 
  {
    const inputFieldInLowerCase = inputField?.toLowerCase();
    if (value?.trim() === "" && isRequired) {
      return `Password is required.`;
    } else if (!passwordValidatorRegex.test(value) && value.trim() !== "") {
      return `The password needs to have at least one capital letter, simple letter and a symbol. And the password needs to be more than 8 characters`;
    } else {
      return null;
    }
}