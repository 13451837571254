import React from "react";
import { Col, Row } from "react-bootstrap";
import Cnaqalogo from "../../../../../_resources/Images/logo.png";
import cnaBanner from "../../../../../_resources/Images/cnaBanner.jpg";
import { Client } from "../../../../../_models/Client.model";
import { useReportContext } from "../../../../../_context/ReportContext";

interface ReportHeaderProps {
  client: Client;
  totalScore: string;
  reportCreateDate: string;
}

export const ReportHeader: React.FC<ReportHeaderProps> = ({
  client,
  totalScore,
  reportCreateDate,
}) => {

  const {currentViewingReport} = useReportContext()
  const area_based_scoring_types =
  process.env.REACT_APP_AREA_BASED_SCORING_TYPES?.split(",");
  const hideSectionForDomesticReports = !area_based_scoring_types?.includes(currentViewingReport.Client.Type.toString())
  return (
    <>
      <Row>
        <Col sm={12} className="d-flex align-conent-center justify-content-end">
          <h1 className="reportHeader">{hideSectionForDomesticReports ? `CNA Inspection Report` : 'Job Completion Report'}</h1>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col sm={12}>
          <img src={cnaBanner} className="w-100" />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col sm={6}>
          <h2 className="reportClientName">{client.Name}</h2>
        </Col>
        <Col sm={6} className="d-flex justify-content-end">
          {hideSectionForDomesticReports && <span className="reportScore">{`${totalScore}%`}</span>}
        </Col>
      </Row>
      <Row className="reportAddressRow">
        <Col sm={6} className="mt-0">
          <span className="reportClientAddress">
            {`${client.Address.Line1.toUpperCase()},`}
            {client.Address.Line2 && `${client.Address.Line2.toUpperCase()},`}
            {`${client.Address.Suburb.toUpperCase()},`}
            {client.Address.Postcode}
          </span>
        </Col>
        <Col sm={6} className="d-flex justify-content-end reportScoreText">
          {hideSectionForDomesticReports && <>Total Score</>} 
        </Col>
      </Row>
      <Row>
        <Col>
          <small>{`Created Date : ${reportCreateDate}`}</small>
        </Col>
      </Row>

      <hr />
    </>
  );
};

