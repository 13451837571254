import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { CleanerBaiscDetails } from "./AddCleanerComponents/CleanerBaiscDetails";
import {
  validateName,
  validateEmail,
  validateContactNumber,
  validateAddressLines,
  validatePostCode,
  validateABN,
  validateTFN
} from "../../Validations";
import { AddedCleaners } from "./AddCleanerComponents/AddedCleaners";
import { useUserContext } from "../../../_context/UserContext";
import { CleanerAddressDetails } from "./AddCleanerComponents/CleanerAddressDetails";
import { submitCleaner } from "../../../_utils/resourceAPI";
import { useCleanerContext } from "../../../_context/CleanerContext";
import EmptyCleaner from "../../../_resources/JsonData/EmptyCleaner.json";
import SuccessfullSubmission from "../Notifications/SuccessfullSubmission";
import { createDateISO, expiredDateISO } from "../../../_constrains/dates";
import { CleanerTaxABN } from "./AddCleanerComponents/CleanerTaxABNData";

export const AddCleaners = () => {
  const { setCurrentPage } = useUserContext();
  const { cleaner, setCleaner } = useCleanerContext();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleShowSuccessModal = () => setShowSuccessModal(true);

  const resetForm = () => {
    setCleaner(EmptyCleaner);
  };

  useEffect(() => {
    setCurrentPage(`Add Cleaners`);
  }, []);

  const submitCleanerHandler = (e: React.FormEvent) => {
    e.preventDefault();
    const newCleanerData = {
      ...cleaner,
      CreateDate: createDateISO,
    }
    submitCleaner(newCleanerData)
      .then((res) => {
        handleShowSuccessModal();
        resetForm();
      })
      .catch((error) => {
        console.log("Something went wrong");
      });
  };

  const isFormValid = () => {
    return (
      !validateName(cleaner.Name, true, "") &&
      !validateEmail(cleaner.Email, true, "") &&
      !validateContactNumber(cleaner.Phone, true, "") &&
      !validateAddressLines(cleaner.Address.Line1, true) &&
      !validateAddressLines(cleaner.Address.Line2, false) &&
      !validateAddressLines(cleaner.Address.Suburb, true) &&
      !validatePostCode(cleaner.Address.Postcode.toString(), true) &&
      !validateABN(cleaner.ABNNo,false) 
      // !validateTFN(cleaner.TaxFileNo,false)
    );
  };

  return (
    <Container className="p-4">
      <Row>
        <Col sm={5}>
          <CleanerBaiscDetails
            cleanerNameValidator={validateName}
            cleanerEmailValidator={validateEmail}
            cleanerContactNumberValidator={validateContactNumber}
          />
          <hr className="mt-4"/>
          <h5 className="mt-4 text-secondary">Address Details</h5>
          <CleanerAddressDetails 
          addressValidator={validateAddressLines}
          postCodeValidator={validatePostCode}
          />
          <hr className="mt-4"/>
          <CleanerTaxABN 
          cleanerABNValidate={validateABN}
          cleanerTFNValidate={validateTFN}
          />
          <Col sm={12} className="mt-4">
            <Button
              className="w-100 btn-success"
              onClick={submitCleanerHandler}
              disabled={!isFormValid()}
            >
              Add Cleaner
            </Button>
          </Col>
        </Col>
        <Col sm={7}>
          <AddedCleaners />
        </Col>
      </Row>
      <SuccessfullSubmission
        show={showSuccessModal}
        onHide={handleCloseSuccessModal}
        successMessage="Cleaner Added Successfully"
      />
    </Container>
  );
};
