import React, { useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import SignaturePad from "react-signature-canvas";

interface SignaturePadComponentProps {
  saveSignature: (base64: string) => void;
}

export const SignaturePadComponent: React.FC<SignaturePadComponentProps> = ({
  saveSignature,
}) => {
  const padRef = useRef<SignaturePad>(null);
  const [isSigned, setIsSigned] = useState(false);

  const clearCanvas = () => {
    padRef.current?.clear();
    setIsSigned(false);
  };

  const saveSignatureInReport = () => {
    if (padRef.current) {
      saveSignature(padRef.current.toDataURL());
    }
  };

  const onEnd = () => {
    if (padRef.current && !padRef.current.isEmpty()) {
      setIsSigned(true);
    }
  };

  return (
    <Row>
      <Col sm={12}>
        <SignaturePad ref={padRef} onEnd={onEnd} canvasProps={{ className: "signatureComponent" }} />
      </Col>
      <Col sm={12}>
        <Row className="mb-4">
          <Col xs={4}>
            <Button onClick={clearCanvas} variant="danger" className="w-100" disabled={!isSigned}>
              Clear
            </Button>
          </Col>
          <Col xs={8}>
            <Button onClick={saveSignatureInReport} variant="success" className="w-100" disabled={!isSigned}>
              Save
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
