import React, {useEffect, useState} from 'react'
import { Button, Col, FormCheck, Modal, Row } from 'react-bootstrap'
import { FaUserPen } from 'react-icons/fa6';
import { FormInput } from '../../../../htmlComponents/FormInput';
import { useCleanerContext } from '../../../../_context/CleanerContext';
import { Cleaner } from '../../../../_models/Cleaner.model';
import { validateContactNumber, validateEmail, validateName,validateAddressLines, validatePostCode, validateTFN, validateABN } from "../../../Validations";
import { deleteCleaner, updateCleaners } from '../../../../_utils/resourceAPI';
import { parseDate } from '../../../../_constrains/dates';

interface UpdateCleanerProps{
  existingCleaner:Cleaner,
  onCleanerupdate: () => void;
  onCleanerDelete:() => void
}


export const UpdateCleaner:React.FC<UpdateCleanerProps> = ({
  existingCleaner,
  onCleanerupdate,
  onCleanerDelete
}) => {
    const [showUpdatecleanerModal, setUpdateCleanerModal] = useState(false);
    const {cleanerToUpdate,setCleanerToUpdate,cleanerUpdated} = useCleanerContext()
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const isFormValid = () => {
      return (
        !validateName(cleanerToUpdate.Name, true, "") &&
        !validateEmail(cleanerToUpdate.Email, true, "") &&
        !validateContactNumber(cleanerToUpdate.Phone, true, "") &&
        !validateAddressLines(cleanerToUpdate.Address.Line1, true) &&
        !validateAddressLines(cleanerToUpdate.Address.Line2, false) &&
        !validateAddressLines(cleanerToUpdate.Address.Suburb, true) &&
        !validatePostCode(cleanerToUpdate.Address.Postcode.toString(), true) &&
        !validateTFN(cleanerToUpdate.TaxFileNo,false) &&
        !validateABN(cleanerToUpdate.ABNNo,false)
      );
    };

    const handleFormSubmit = () => {
      updateCleaners(cleanerToUpdate.Id, cleanerToUpdate)
        .then((res) => {
          console.log(res.data);
          console.log("clear Cleaner");
          onCleanerupdate();
          setUpdateCleanerModal(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };


    const handleDeleteCleaner = () => {
      deleteCleaner(cleanerToUpdate.Id)
        .then(response => {
          setShowConfirmationModal(false);
          onCleanerDelete()
        })
        .catch(error => {
          console.error("Error deleting client:", error);
          // Optionally, handle the error here, such as showing a notification to the user
        });
    }

  return (
    <>
<Button 
    className="btn-warning w-100" 
    onClick={() => {
        setUpdateCleanerModal(true);
        setCleanerToUpdate(existingCleaner);
    }}
>
    Update Cleaner
</Button>
    <Modal
      show={showUpdatecleanerModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setUpdateCleanerModal(false)}
    >
      <Modal.Header closeButton onHide={() => setUpdateCleanerModal(false)}>
        <Modal.Title id="contained-modal-title-vcenter">
        <FaUserPen /> Update Cleaner
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Row>
      <Col sm={12}>
        <FormInput
          id="CleanersName"
          label="Cleaner Name"
          type="text"
          value={cleanerToUpdate.Name}
          required={true}
          onChange={(value: string) => setCleanerToUpdate({ ...cleanerToUpdate, Name: value })}
          validator={validateName}
        />
      </Col>
      <Col sm={12}>
        <FormInput
          id="CleanerEmail"
          label="Cleaner Email"
          type="email"
          value={cleanerToUpdate.Email}
          required={true}
          onChange={(value: string) => setCleanerToUpdate({ ...cleanerToUpdate, Email: value })}
          validator={validateEmail}
        />
      </Col>
      <Col sm={12}>
        <FormInput
          id="CleanerContact"
          label="Cleaner Contact Number"
          type="text"
          value={cleanerToUpdate.Phone}
          required={true}
          onChange={(value: string) => setCleanerToUpdate({ ...cleanerToUpdate, Phone: value })}
          validator={validateContactNumber}
        />
      </Col>

      <Col sm={12} className="mt-2">
  <FormInput
    id="CleanerStartDate"
    label="Cleaner Start Date"
    type="date"
    value={cleanerToUpdate.StartDate ? cleanerToUpdate.StartDate.split('T')[0] : ''}
    required={true}
    onChange={(value) => {
      if (!value) {
        // Handle the case when the date is cleared
        setCleanerToUpdate({ ...cleanerToUpdate, StartDate: '' });
        return;
      }

      // Proceed only if the value is a valid date
      const selectedDate = parseDate(value);
      if (!isNaN(selectedDate.getTime())) {
        // Convert the Date object to an ISO string
        const isoDate = selectedDate.toISOString();
        // Update the state with the ISO string
        setCleanerToUpdate({ ...cleanerToUpdate, StartDate: isoDate });
      }
    }}
  />
</Col>
<Col sm={12} className="mt-2">
  <FormInput
    id="CleanerEndDate"
    label="Cleaner End Date"
    type="date"
    value={cleanerToUpdate.EndDate ? cleanerToUpdate.EndDate.split('T')[0] : ''}
    required={true}
    onChange={(value) => {
      if (!value) {
        // Handle the case when the date is cleared
        setCleanerToUpdate({ ...cleanerToUpdate, EndDate: '' });
        return;
      }

      // Proceed only if the value is a valid date
      const selectedDate = parseDate(value);
      if (!isNaN(selectedDate.getTime())) {
        // Check if the end date is after the start date
        const startDate = new Date(cleanerToUpdate.StartDate);
        if (selectedDate > startDate) {
          // Convert the Date object to an ISO string
          const isoDate = selectedDate.toISOString();
          // Update the state with the ISO string
          setCleanerToUpdate({ ...cleanerToUpdate, EndDate: isoDate });
        } else {
          // Handle the case when the end date is before the start date
          alert("End Date must be after the Start Date.");
          // Optionally, reset the end date
          // setCleaner({ ...cleaner, EndDate: '' });
        }
      }
    }}
  />
</Col>

      
      <Col sm={12} className='mt-2'>
      <hr className='mt-4'/>
        <FormInput
          id="CleanerAddressLine1"
          label="Cleaner Address Line 1"
          type="text"
          value={cleanerToUpdate.Address.Line1}
          required={true}
          onChange={(value: string) =>
            setCleanerToUpdate({
              ...cleanerToUpdate,
              Address: {
                ...cleanerToUpdate.Address,
                Line1: value,
              },
            })
          }
          validator={validateAddressLines}
        />
      </Col>
      <Col sm={12} className='mt-1'>
        <FormInput
          id="CleanerAddressLine2"
          label="Cleaner Address Line 2"
          type="text"
          value={cleanerToUpdate.Address.Line2}
          required={true}
          onChange={(value: string) =>
            setCleanerToUpdate({
              ...cleanerToUpdate,
              Address: {
                ...cleanerToUpdate.Address,
                Line2: value,
              },
            })
          }
          validator={validateAddressLines}
        />
      </Col>
      <Col sm={6} className='mt-1'>
        <FormInput
          id="CleanerAddressSuberb"
          label="Cleaner Address Suberb"
          type="text"
          value={cleanerToUpdate.Address.Suburb}
          required={true}
          onChange={(value: string) =>
            setCleanerToUpdate({
              ...cleanerToUpdate,
              Address: {
                ...cleanerToUpdate.Address,
                Suburb: value,
              },
            })
          }
          validator={validateAddressLines}
        />
      </Col>
      <Col sm={6} className='mt-1'>
        <FormInput
          id="CleanerAddressPostcode"
          label="Cleaner Postcode"
          type="text"
          value={cleanerToUpdate.Address.Postcode.toString()}
          required={true}
          onChange={(value: string) =>
            setCleanerToUpdate({
              ...cleanerToUpdate,
              Address: {
                ...cleanerToUpdate.Address,
                Postcode: parseInt(value, 10),
              },
            })
          }
          validator={validateAddressLines}
        />
      </Col>

      <Col sm={12}>
      <hr className='mt-4'/>
        <FormInput
          id="CleanerTFN"
          label="Cleaner TFN"
          type="text"
          value={cleanerToUpdate.TaxFileNo}
          required={true}
          onChange={(value: string) => setCleanerToUpdate({ ...cleanerToUpdate, TaxFileNo: value })}
          validator={validateTFN}
        />
      </Col>
      <Col sm={12}>
        <FormInput
          id="CleanerABN"
          label="Cleaner ABN"
          type="text"
          value={cleanerToUpdate.ABNNo}
          required={true}
          onChange={(value: string) => setCleanerToUpdate({ ...cleanerToUpdate, ABNNo: value })}
          validator={validateABN}
        />
      </Col>
      <Col sm={12}>
      <FormCheck
  id="CleanerACN"
  label="Does cleaner have an ACN?"
  type="checkbox"
  checked={cleanerToUpdate.ACN}
  onChange={(event) => setCleanerToUpdate({ ...cleanerToUpdate, ACN: event.target.checked })}
  className='mt-2 mb-2'
/>

        
      </Col>
      <Col sm={12}>
      <Row>
      <Col sm={6} >
      <Button variant="danger" className="w-100 mt-2" onClick={() =>{setShowConfirmationModal(true)}}>
                  Delete Cleaner
                </Button>
                </Col>
                <Col sm={6} >
        <Button className="w-100 btn-success mt-2" onClick={handleFormSubmit} disabled={!isFormValid()}>
          Update Cleaner
        </Button>
      </Col>
      </Row>

      </Col>

    </Row>
      </Modal.Body>
    </Modal>
    <Modal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure that you want to delete this cleaner?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmationModal(false)}>
            No
          </Button>
          <Button variant="danger" onClick={handleDeleteCleaner}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
