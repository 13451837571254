import React, { useEffect, useState } from 'react'
import { useReportContext } from '../../../_context/ReportContext'
import { Button, Col, Row } from 'react-bootstrap'
import { ClientType } from '../../../_models/Client.model'
import { useCreateReportNotificationContext } from '../../../_context/CreateReportNotificationContext'
import { ClientGotConsent } from './ClientConsent/ClientGotConsent'
import { ClientNoConsent } from './ClientConsent/ClientNoConsent'
import { useClientContext } from '../../../_context/ClientContext'
import { useNavigate } from 'react-router-dom'



export const ClientConsent = () => {
  const navigate = useNavigate();
  const {newReport} = useReportContext()
  const [consentGiven,setConsentGiven] = useState<boolean>(true)
  const { clientForReporting } = useClientContext();
  const { setDisplayNotification, setNotification } = useCreateReportNotificationContext();


  useEffect(() => {
    if (clientForReporting.Name === "") {
      setDisplayNotification(true);
      setNotification("Please select a Client to create reports");
      navigate("/reports/select_client_for_new_report");
    }
  }, []);

  return (
    
    <Row>
      <Col sm={12}>
        <h4 style={{ fontWeight: 300 }} className="mb-4">
          Please get client consent before you access the property for inspection
        </h4>
      </Col>
        <Col sm={12} className="concentBG">
          <p>
            We, {newReport.Client?.Name} {newReport.Client?.Type === ClientType.AirBNB ? `(${newReport.ClientName})` : ``}, acknowledge that CNA
            Cleaning Services will be conducting cleaning inspections at their
            facility. We understand that this is necessary to maintain a clean
            and safe environment for employees and customers. I consent to CNA
            Cleaning Services entering the premises for the purpose of
            conducting these inspections.
          </p>
          <p>
            Thank you for your cooperation in helping us maintain a safe and
            clean environment. If you have any questions or concerns, please do
            not hesitate to contact us. <br />
            Sincerely,
          </p>
          <p>
            CNA Cleaning
          </p>
        </Col>
        <Row>
        <Col sm={2}></Col>
        <Col sm={4}>
          <Button variant='danger' className='generalButton' onClick={() => setConsentGiven(false)}>
            No Consent
          </Button>
        </Col>
        <Col sm={4}>
          <Button className='generalButton' onClick={() => setConsentGiven(true)}>
            Got Consent
          </Button>
        </Col>
        <Col sm={2}></Col>
        </Row>

 
        <Row>
          {consentGiven ? <ClientGotConsent/> : <ClientNoConsent/> }
        </Row>
      </Row> 
      
  )
}
