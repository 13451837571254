import React,{useEffect} from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'
import './Scoring/scoringStyles/scoring.css'

export const CreateReportLayout = () => {



  useEffect(() => {
    const handleScroll = (e:Event) => {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        const scrollHeight = document.documentElement.scrollHeight;
        const clientHeight = document.documentElement.clientHeight;
        const bottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

        if (bottom) {
            e.preventDefault();
            // Add any custom behavior here if needed
        }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup function
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []); // Empty dependency array means this effect runs once on mount and then on unmount



  return (
    <Container>
        <Row>
            <Col sm={12}>
            </Col>
        </Row>
        <Row>
        <Col sm={12}>
            <Outlet/>
            </Col>
        </Row>
    </Container>
  )
}
