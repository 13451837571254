import React, { useEffect } from "react";
import { useUserContext } from "../../_context/UserContext";
import { redirect, useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { SquareButtonComponent } from "../../htmlComponents/squareButtonComponent";
import ReportButtons from '../../_resources/Admin/ReportButtons.json'

export const Reports = () => {
  const { setCurrentPage,loggedUser,isLogged } = useUserContext();
  const navigate = useNavigate()

  useEffect(() => {
    if(!isLogged){
      navigate("/")
    }
    setCurrentPage("Reprts Page")
  },[isLogged])

  useEffect(() => {
    setCurrentPage("Reprts Page")
  },[])

  return (
    <Container>
      <Row>
      {
            Object.entries(ReportButtons).map(([buttonText, {toPage, key, cssClasses}]) => (
              <Col sm={4} key={key} className="mt-4">
                <SquareButtonComponent
                  buttonText={buttonText}
                  toPage={toPage}
                  key={key}
                  cssClasses={cssClasses}
                />
              </Col>
            ))
        }
      </Row>
    </Container>
  );
};
