import React, { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useClientContext } from "../../../_context/ClientContext";
import { useCreateReportNotificationContext } from "../../../_context/CreateReportNotificationContext";
import { ClientType } from "../../../_models/Client.model";
import { useReportContext } from "../../../_context/ReportContext";

export const ConfirmClient = () => {
  const area_based_scoring_types =
    process.env.REACT_APP_AREA_BASED_SCORING_TYPES?.split(",");
  const navigate = useNavigate();
  const { clientForReporting } = useClientContext();
  const { setNewReport, updateReportedDate, newReport } = useReportContext();
  const { setDisplayNotification, setNotification } =
    useCreateReportNotificationContext();

  useEffect(() => {
    if (clientForReporting.Name === "") {
      setDisplayNotification(true);
      setNotification("Please select a Client to create reports");
      navigate("/reports/select_client_for_new_report");
    }
    updateReportedDate();
  }, []);

  const setIsInternalReport = (isInternal: boolean) => {
    setNewReport((reportOnCreate) => ({
      ...reportOnCreate,
      IsInternalReport: isInternal,
    }));
    isInternal
      ? navigate("/reports/create_new_report/scoring")
      : newReport.Client.Type !== ClientType.Domestic
      ? navigate("/reports/create_new_report/client-consent")
      : navigate("/reports/create_new_report/scoring");
  };

  return (
    <Row>
      <Col sm={12}>
        <h4 style={{ fontWeight: 300 }} className="mb-4">
          You have selected the below client to create the report
        </h4>
      </Col>
      <Col sm={12} style={{ fontSize: "20px", fontWeight: 300 }}>
        <strong>
          <span>{`${clientForReporting.Name} (${
            ClientType[clientForReporting.Type]
          })`}</span>
        </strong>
      </Col>
      <Col sm={12}>
        <span>{clientForReporting.Email}</span>
      </Col>
      <Col sm={12} className="mt-4">
        There are 2 Types of reports in the CNA reporting system.
        <ul>
          <li>Internal Reports</li>
          <li>Inspection Reports</li>
        </ul>
      </Col>
      <Col sm={12} className="text-justify">
        <span>
          In you are doing a internal report, you don't have to get the client
          consent on that. If you are a cleaner who is creating a Report as a
          part of your domestic cleaning use internal reports. If it's a termly
          inspection, use inspection reports
        </span>
      </Col>
      <Col sm={12} className="mt-4">
        <Col sm={2}></Col>
        <Col sm={4}>
          <Button
            variant="warning"
            className="generalButton"
            onClick={() => {
              setIsInternalReport(true);
            }}
          >
            Internal Report
          </Button>
        </Col>
        <Col sm={4}>
          {area_based_scoring_types?.includes(
            newReport.Client.Type.toString()
          ) ? (
            <Button
              variant="success"
              className="generalButton"
              onClick={() => {
                setIsInternalReport(false);
              }}
            >
              Domestic Inspection Report
            </Button>
          ) : (
            <Button
              variant="success"
              className="generalButton"
              onClick={() => {
                setIsInternalReport(false);
              }}
            >
              Inspection Report
            </Button>
          )}
        </Col>
        <Col sm={2}></Col>
      </Col>
    </Row>
  );
};
