import React, { ReactNode, createContext, useContext, useState } from 'react'
import { LoginUserResponce } from '../_models/LoginUserResponce.model'
import EmptyLoggedInUser from '../_resources/JsonData/EmptyLoggedUser.json'
import { userLogin } from '../_utils/resourceAPI'

interface LoggedUserContextProps{
    loggedUser:LoginUserResponce
    setLoggedUser:React.Dispatch<React.SetStateAction<LoginUserResponce>>
    loginError:string,
    setLoginError:React.Dispatch<React.SetStateAction<string>>
    login: ( 
        username: string,
        password: string,
        role: string
      ) => Promise<LoginUserResponce | null>;
}

const LoggedInUserContext = createContext<LoggedUserContextProps|null>(null)

export const useLoggedInUserContext = () => {
    const context = useContext(LoggedInUserContext)
    if(!context){
        throw new Error("Component is not inside the context provider")
    }
    return context
}

export const LoggedUserContextProvider: React.FC<{children:ReactNode}> = ({
    children
}) => {

    const [loggedUser,setLoggedUser] = useState(EmptyLoggedInUser);
    const [loginError,setLoginError] = useState("")

    const setLoginErrorMessage = (errorMessage:string) => {
        setLoggedUser(EmptyLoggedInUser)
        setLoginError(errorMessage)
    }

    const login = async (username:string,password:string,role:string) => {
        try {
            const response = await userLogin(username, password);
            const loginResponce:LoginUserResponce = response.data;
            if(response.status === 200 && loginResponce.data.Role === role){
                setLoggedUser(response.data)
                return loginResponce
            }else if(response.status === 200 && loginResponce.data.Role !== role){
                setLoginErrorMessage("You are trying to log in with an invalid user role.")
                return EmptyLoggedInUser;
            }
            else{
                setLoginErrorMessage("There was a problem when we were trying to log you in. Pease try again.")
                return EmptyLoggedInUser;
            }
        } catch (error) {
            setLoginErrorMessage("Failed to log in.")
            return EmptyLoggedInUser;
        }
    }

    return(
        <LoggedInUserContext.Provider value={{loggedUser,setLoggedUser,login,loginError,setLoginError}}>
            {children}
        </LoggedInUserContext.Provider>
    )

}