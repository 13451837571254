import React from 'react'
import { Item } from '../../../../../_models/Report.model'
import { Table } from 'react-bootstrap'
import { FaRegStar, FaStar } from 'react-icons/fa6';
import { useReportContext } from '../../../../../_context/ReportContext';

interface ReportItemViewProps{
    Items:Item[]
}


const renderScoreStars = (mark: number) => {
    const scoreStars = [];
    if(mark === 0){
            scoreStars.push(<small className='text-secondary'>Not Applicable</small>)
    }else{
        for (let score = 1; score <= 5; score++) {
            if (score <= mark) {
              scoreStars.push(<FaStar key={score} className="itemScoreColoredStar" />);
            } else {
              scoreStars.push(<FaRegStar key={score} className="itemScoreUncoloredStar" />);
            }
          }
    }
    
    return scoreStars;
  };


export const ReportItemView:React.FC<ReportItemViewProps> = ({Items}) => {

  

  return (
    <Table  hover className='itemTable'>
        <tbody>
            {Items.map(item => (
                 <>
                 <tr>
                     <td>{item.Name}</td>
                     <td>{renderScoreStars(item.Mark)}</td>
                     {<td>{item.Comment}</td>}
                 </tr>
                 {item.Images && item.Images.length > 0 && (
                     <tr >
                         <td colSpan={3} className='imageTDColor'>
                            {item.Images.map(image => (<img src={image} className='reportItemImage'/>))}
                         </td>
                     </tr>
                 )}
             </>
            ))}
            </tbody>
    </Table>
  )
}
