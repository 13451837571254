import React, { ReactNode, createContext, useContext, useState } from 'react'
import { Cleaner } from '../_models/Cleaner.model';
import EmptyCleaner from '../_resources/JsonData/EmptyCleaner.json'

interface CleanerContextProps{
    cleaner: Cleaner;
    setCleaner: React.Dispatch<React.SetStateAction<Cleaner>>;
    cleanerToUpdate: Cleaner;
    setCleanerToUpdate: React.Dispatch<React.SetStateAction<Cleaner>>;
    cleanerUpdated:boolean;
    setCleanerUpdated:React.Dispatch<React.SetStateAction<boolean>>;
    cleanerDeleted:boolean;
    setCleanerDeleted:React.Dispatch<React.SetStateAction<boolean>>;
}

const CleanerContext = createContext<CleanerContextProps | null>(null) 

export const useCleanerContext = () => {
    const context = useContext(CleanerContext);
    if (!context) {
      throw new Error('userCleanerContext must be used within a CleanerContextProvider');
    }
    return context;
  };
  
  interface CleanerContextProviderProps {
    children: ReactNode;
  }
  
  export const CleanerContextProvider: React.FC<CleanerContextProviderProps> = ({ children }) => {
    // Check if there's any cleaner data in localStorage on initial load
    const initialCleaner = localStorage.getItem('cleaner') 
      ? JSON.parse(localStorage.getItem('cleaner') as string) 
      : EmptyCleaner;
    
    const [cleaner, setCleaner] = useState<Cleaner>(initialCleaner);
    const [cleanerToUpdate, setCleanerToUpdate] = useState<Cleaner>(initialCleaner);
    const [cleanerUpdated, setCleanerUpdated] = useState<boolean>(false);
    const [cleanerDeleted, setCleanerDeleted] = useState<boolean>(false);


    
    const handleSetCleaner: React.Dispatch<React.SetStateAction<Cleaner>> = (value) => {
      const newCleaner = typeof value === 'function' ? value(cleaner) : value;
  
      // Save the new client data to localStorage
      localStorage.setItem('cleaner', JSON.stringify(newCleaner));
  
      // Update the client state
      setCleaner(newCleaner);
  };
  
  
    return (
      <CleanerContext.Provider value={{cleaner, setCleaner: handleSetCleaner,cleanerToUpdate, setCleanerToUpdate, cleanerUpdated,setCleanerUpdated,cleanerDeleted, setCleanerDeleted}}>
        {children}
      </CleanerContext.Provider>
    );
  };
  
  