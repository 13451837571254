import React, { ReactNode, createContext, useContext, useState } from 'react'
import { Client } from '../_models/Client.model';
import EmptyClient from '../_resources/JsonData/EmptyClient.json'

interface ClientContextProps{
    client: Client;
    setClient: React.Dispatch<React.SetStateAction<Client>>;
    clientForReporting : Client 
    setClientForReporting : React.Dispatch<React.SetStateAction<Client>> ;
    clientUpdated:boolean;
    setClientUpdated: React.Dispatch<React.SetStateAction<boolean>>;
    clientDeleted:boolean;
    setClientDeleted: React.Dispatch<React.SetStateAction<boolean>>;
    clientAdded:boolean;
    setClientAdded: React.Dispatch<React.SetStateAction<boolean>>;
    allClients :Client[], 
    setAllClients :React.Dispatch<React.SetStateAction<Client[]>>;
}

const ClientContext = createContext<ClientContextProps | null>(null) 

export const useClientContext = () => {
    const context = useContext(ClientContext);
    if (!context) {
      throw new Error('useClientContext must be used within a ClientContextProvider');
    }
    return context;
  };
  
  interface ClientContextProviderProps {
    children: ReactNode;
  }
  
  export const ClientContextProvider: React.FC<ClientContextProviderProps> = ({ children }) => {
    // Check if there's any client data in localStorage on initial load
    const initialClient = localStorage.getItem('client') 
      ? JSON.parse(localStorage.getItem('client') as string) 
      : EmptyClient;

    const reportingClient = localStorage.getItem('reportingClient') 
      ? JSON.parse(localStorage.getItem('reportingClient') as string) 
      : EmptyClient;      
    
    const [client, setClient] = useState<Client>(initialClient);  
    const [clientForReporting, setClientForReporting] = useState<Client>(reportingClient);
    const [clientUpdated, setClientUpdated] = useState<boolean>(false);
    const [clientDeleted, setClientDeleted] = useState<boolean>(false);

    const [clientAdded, setClientAdded] = useState<boolean>(false);
    const [allClients, setAllClients] = useState<Client[]>([]);

    
  
    const handleSetClient: React.Dispatch<React.SetStateAction<Client>> = (value) => {
      const newClient = typeof value === 'function' ? value(client) : value;
  
      // Save the new client data to localStorage
      localStorage.setItem('client', JSON.stringify(newClient));
  
      // Update the client state
      setClient(newClient);
  };

  const handleSetReportingClient: React.Dispatch<React.SetStateAction<Client>> = (value) => {
    const reportingClient = typeof value === 'function' ? value(clientForReporting) : value;

    // Save the new client data to localStorage
    localStorage.setItem('reportingClient', JSON.stringify(reportingClient));

    // Update the client state
    setClientForReporting(reportingClient);
};
  
  
    return (
      <ClientContext.Provider value={{client, setClient: handleSetClient, clientUpdated, setClientUpdated, clientAdded, setClientAdded, allClients, setAllClients, clientForReporting, setClientForReporting:handleSetReportingClient,clientDeleted, setClientDeleted}}>
        {children}
      </ClientContext.Provider>
    );
  };
  
  