import React, {useState} from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { useUserContext } from "../../_context/UserContext";
import { FaSignOutAlt } from "react-icons/fa";

export const AdminLayout = () => {
  const { currentPage,logout } = useUserContext();
  const loggedInUserName = localStorage.getItem("loggedInUserName");
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleCloseLogoutModal = () => setShowLogoutModal(false);
  const handleShowLogoutModal = () => setShowLogoutModal(true);
  const handleConfirmLogout = () => {
    logout();
    handleCloseLogoutModal();
  };


  return (
    <Container fluid>
      <Row className="headerConainer">
        <Col>
          <h1>{currentPage}</h1>
        </Col>
        <Col sm={3} className="userName">
          <p className="text-end">{loggedInUserName}</p>
        </Col>
        <Col xs={2} sm={1} className="mb-2">
        <Button className="w-100" variant="outline-danger" onClick={handleShowLogoutModal}><FaSignOutAlt/></Button>
        </Col>
        <Modal show={showLogoutModal} onHide={handleCloseLogoutModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to log out?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseLogoutModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
      </Row>
      <Outlet/>
    </Container>
  );
};
