import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { AddedCleaners } from '../AddCleaners/AddCleanerComponents/AddedCleaners'
import { useUserContext } from '../../../_context/UserContext'

export const ViewCleaners = () => {

  const {setCurrentPage} = useUserContext()

  useEffect(() => {
    setCurrentPage("View Cleaners")
  }, []);



  return (
    <Container className='p-4'>
        <AddedCleaners/>
    </Container>
  )
}
