import React from 'react'
import { Alert } from 'react-bootstrap'

interface AlertProps {
    variant:string,
    key:string,
    message:string,
    className:string
}


export const AlertComponent:React.FC<AlertProps> = ({
    variant,
    key,
    message,
    className
}) => {
  return (
    <Alert key={key} variant={variant} className={className}>
        {message}
  </Alert>
  )
}
