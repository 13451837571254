import React from 'react'
import {styles} from './PDFReportCSS'
import { ClientConcent } from '../../../_models/Report.model';
import { Image, Text, View } from '@react-pdf/renderer';
import { Client } from '../../../_models/Client.model';

interface PDFReportSignatureAreaProps {
    ClientSignature:string,
    ClientConcent:ClientConcent,
    CNASignature:string,
    InspectedBy:string,
    Client:Client
  }




export const PDFReportSignatures:React.FC<PDFReportSignatureAreaProps> = ({
    ClientSignature,ClientConcent,CNASignature,InspectedBy,Client
}) => {

  const area_based_scoring_types =
  process.env.REACT_APP_AREA_BASED_SCORING_TYPES?.split(",");
  const hideSectionForDomesticReports = !area_based_scoring_types?.includes(Client.Type.toString())


  return (
    
    <View key={'SignatureArea'}  wrap={false}>
        <View style={{...styles.row,marginTop:"20px"}}> 
        <View style={{...styles.col,width:"100%"}}>

<Text style={{...styles.areaTitle,marginBottom:"10px"}}>
      Inspected and Approved By
    </Text>
    </View>
        </View>
        <View style={{...styles.row,marginTop:"10px"}}>


        <View style={{ ...styles.col, width: "50%"}}>
          <View style={styles.row}>
          <View style={{...styles.col,paddingLeft:"20%"}}>
            <Image src={ClientSignature} style={{height:"40px"}} />
            {!hideSectionForDomesticReports  ? <Text style={styles.paragapthText}>
              {Client.Name}
            </Text> :
            <Text style={styles.paragapthText}>
              {ClientConcent.Name}
            </Text>
}
{hideSectionForDomesticReports &&
 <Text style={{...styles.paragapthText,color:"grey"}}>
 {ClientConcent.Designation}
</Text>
}
           
          
          </View>
          </View>

        </View>
        <View style={{ ...styles.col, width: "50%"}}>
          <View style={styles.row}>
          <View style={{...styles.col, paddingLeft:"20%"}}>
            <Image src={CNASignature} style={{height:"40px"}}/>
            <Text style={styles.paragapthText}>
              {InspectedBy}
            </Text>
            <Text style={{...styles.paragapthText,color:"grey"}}>
              CNA Cleaning Inspector
            </Text>
          </View>
          </View>

        </View>
        </View>
    </View>
    )
}
