import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useUserContext } from '../../../_context/UserContext'
import { AddedUsers } from '../AddUsers/AddUserComponents/AddedUsers'

export const ViewUsers = () => {

  const {setCurrentPage} = useUserContext()

  useEffect(() => {
    setCurrentPage("View Users")
  }, []);



  return (
    <Container className='p-4'>
        <AddedUsers/>
    </Container>
  )
}
