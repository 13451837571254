
import { Text, View } from '@react-pdf/renderer';
import {styles} from './PDFReportCSS'
import { PDFReportSubAreas } from './PDFReportSubArea';
import { Area } from '../../../_models/Report.model';
import { Client } from '../../../_models/Client.model';
import { PDFReportSubAreaAndItemView } from './DomesticReportSpecificElements/PDFReportSubAreaAndItemView';
import { PDFReportAreaScoring } from './DomesticReportSpecificElements/PDFReportAreaScoring';

interface PDFReportAreaProps {
    areas: Area[];
    client : Client
  }
  

export const PDFReportAreas:React.FC<PDFReportAreaProps> = ({
    areas,
    client
  }) => {
    
  const area_based_scoring_types =
  process.env.REACT_APP_AREA_BASED_SCORING_TYPES?.split(",");
  const hideSectionForDomesticReports = !area_based_scoring_types?.includes(client.Type.toString())
  const mainSectionWidth = hideSectionForDomesticReports ? "100%" : "70%"
  return (
<>
        {areas.map( (area,index) => (
          <View key={`areaDetails${index}`} wrap={false}>
            <View style={styles.row}>
            <View style={{...styles.col,width:mainSectionWidth}}>
            <View style={styles.areaSection}>
              <Text style={styles.areaTitle}>{area.Name}</Text>
              {hideSectionForDomesticReports && <PDFReportSubAreas subAreas={area.SubAreas}/>}
              {!hideSectionForDomesticReports && <PDFReportSubAreaAndItemView subAreas={area.SubAreas}/>}
           </View>
</View>
{!hideSectionForDomesticReports && 
<View style={{...styles.col,width:"30%",}}>
<View style={styles.areaSection}>
  <PDFReportAreaScoring SubAreas={area.SubAreas} />
</View>
</View>
  }
            </View>

          </View>
        ))}
          
        </>
  );
};

