import React, { useEffect, useState } from 'react'
import { Client } from '../../../../_models/Client.model';
import { getAllClients } from '../../../../_utils/resourceAPI';
import {  Col, Container, FormControl, Row, Spinner, Table } from 'react-bootstrap';
import { UpdateClient } from '../UpdateClients/UpdateClient';
import { useUserContext } from '../../../../_context/UserContext';
import { FaClipboardList } from 'react-icons/fa6';
import { useClientContext } from '../../../../_context/ClientContext';
import { ClientUpdatedSuccessModal } from '../UpdateClients/ClientUpdatedSuccessModal';
import EmptyClient from '../../../../_resources/JsonData/EmptyClient.json'
import { ClientDeleteSuccessModal } from '../UpdateClients/ClientDeletedSuccessModal';
import { ClientSearchAndFilter } from '../../../../htmlComponents/clientSearchAndFilter';

export const AddedClients = () => {
  const [filteredClients, setFilteredClients] = useState<Client[]>([]);
  const [noDataOrLoadingData, setNoDataOrLoadingData] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const{setCurrentPage} = useUserContext()
  const{setClientUpdated,setClient,allClients,setAllClients,setClientDeleted} = useClientContext()

  const fetchClients = () => {
    getAllClients()
      .then((res) => {
        const allCleaners: Client[] = res.data;
        setAllClients(allCleaners);
      })
      .catch((error) => {
        console.log("Failed to fetch cleaners");
      });
  };

  useEffect(() => {
    setClient(EmptyClient)
    // fetchClients();
    setCurrentPage("View Clients")
  }, []);

  // const filteredClients = allClients.filter(
  //   (client) =>
  //     client.Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     client.Email.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     client.Address.Line1.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     client.Address.Line2?.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     client.Address.Suburb.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     client.Address.Postcode.toString().toLowerCase().includes(searchTerm.toLowerCase())
  // );


  const onClientUpdated = () => {
    fetchClients();
    setClientUpdated(true)
  };
  
  const onClientDeleted = () => {
    fetchClients();
    setClientDeleted(true)
  }



  return (
    <Container className='p-4'>
      
    <Row>
      <ClientSearchAndFilter
        setFiltererdClients={setFilteredClients}
        setDataStatus={setNoDataOrLoadingData}
      />
      {filteredClients.length > 0 ? (
        <Row>
        <Col sm={12}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Address</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredClients.map((client) => {
              return client ? (
                <tr key={client.Id}>
                  <td>{client.Name}</td>
                  <td>{client.Email}</td>
                  <td>{`${client.Address.Line1} ${client.Address.Line2} ${client.Address.Postcode} ${client.Address.Suburb}`}</td>
                  <td><UpdateClient clientToUpdate={client} onClientUpdated={onClientUpdated} onClientDeleted={onClientDeleted}/></td>
                </tr>
              ) : null;
            })}
          </tbody>
        </Table>
      </Col>
      </Row>
      ):<Row>
      <Col className="text-center">
        {noDataOrLoadingData === "Loading Clients" ? (
          <Spinner animation="border" role="status" />
        ) : (
          <>{noDataOrLoadingData}</>
        )}
      </Col>
    </Row>}
    </Row> 
    <ClientUpdatedSuccessModal/>
    <ClientDeleteSuccessModal/>
    </Container>
  )
}
