import React from 'react'
import { Modal } from 'react-bootstrap'
import { useCleanerContext } from '../../../../_context/CleanerContext'

export const CleanerDeleteSuccessModal = () => {

    const{setCleanerDeleted,cleanerDeleted} = useCleanerContext()

  return (
    <Modal
    show={cleanerDeleted}
    size="xl"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    onHide={() => setCleanerDeleted(false)}
    >
        <Modal.Header  closeButton onHide={() => setCleanerDeleted(false)}>
           <Modal.Title>Success</Modal.Title> 
        </Modal.Header>
        <Modal.Body>
            Cleaner Deleted Successfully
        </Modal.Body>
        
    </Modal>
  )
}
