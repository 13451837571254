import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useRef,
} from "react";
import { userLogin } from "../_utils/resourceAPI";
import { User } from "../_models/User.model";
import EmptyUser from "../_resources/JsonData/EmptyUser.json";
import EmptyLoggedUser from "../_resources/JsonData/EmptyLoggedUser.json";

import { LoginUserResponce } from "../_models/LoginUserResponce.model";

interface userContextProps {
  newUser: User;
  setNewUser: React.Dispatch<React.SetStateAction<User>>;
  userToUpdate: User;
  setUserToUpdate: React.Dispatch<React.SetStateAction<User>>;
  userUpdated: boolean;
  showLoginLoadingScreen: boolean;
  setUserUpdated: React.Dispatch<React.SetStateAction<boolean>>;
  currentPage: string;
  isLogged: boolean;
  isUserAdmin: boolean;
  loginError: string;
  userName: string;
  jwtToken: string;
  loggedUser: LoginUserResponce | undefined;
  setLoggedUser: React.Dispatch<
    React.SetStateAction<LoginUserResponce | undefined>
  >;
  setCurrentPage: React.Dispatch<React.SetStateAction<string>>;
  setLoginError: React.Dispatch<React.SetStateAction<string>>;
  setIsUserAdmin: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLogged: React.Dispatch<React.SetStateAction<boolean>>;
  setUserName: React.Dispatch<React.SetStateAction<string>>;
  setJwtToken: React.Dispatch<React.SetStateAction<string>>;
  setShowLoginLoadingScreen: React.Dispatch<React.SetStateAction<boolean>>;

  login: ( 
    username: string,
    password: string,
    role: string
  ) => Promise<string | null>; // Add the login function here
  logout: () => void;
}

const UserContext = createContext<userContextProps | null>(null);

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserContextProvider");
  }
  return context;
};

interface userContextProviderProps {
  children: ReactNode;
}

export const UserContextProvider: React.FC<userContextProviderProps> = ({
  children,
}) => {
  const initialNewUser = localStorage.getItem("newUser")
    ? JSON.parse(localStorage.getItem("newUser") as string)
    : EmptyUser;

  const [isLogged, setIsLogged] = useState(false);
  const [showLoginLoadingScreen,setShowLoginLoadingScreen] = useState(false)
  const [userUpdated, setUserUpdated] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [userName, setUserName] = useState("");
  const [currentPage, setCurrentPage] = useState("");
  const [loggedUser, setLoggedUser] = useState<LoginUserResponce | undefined>(
    undefined
  );
  const [jwtToken, setJwtToken] = useState(() => {
    // When initializing the state, try to read the JWT token from localStorage
    const savedJwtToken = localStorage.getItem("jwtToken");
    return savedJwtToken ? savedJwtToken : "no Token Found";
  });
  const [newUser, setNewUser] = useState<User>(initialNewUser);
  const [userToUpdate, setUserToUpdate] = useState<User>(initialNewUser);

  useEffect(() => {
    // Step 2: Check local storage for loggedUser data
    const savedLoggedUser = localStorage.getItem("loggedUser");
    if (savedLoggedUser) {
      const parsedLoggedUser: LoginUserResponce = JSON.parse(savedLoggedUser);
      setLoggedUser(parsedLoggedUser);
      setIsLogged(true);
      setIsUserAdmin(parsedLoggedUser.data.Role === "admin");
    } else {
      // If no loggedUser is found in local storage, initialize with EmptyLoggedUser
      console.log("no user found");
      setLoggedUser(EmptyLoggedUser);
    }
  }, []);

  // Each time jwtToken changes, we save the new value in localStorage
  useEffect(() => {
    if (jwtToken) {
      localStorage.setItem("jwtToken", jwtToken);
      localStorage.setItem("loggedInUserName", userName);
    } else {
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("loggedInUserName");
    }
  }, [jwtToken]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      if (loggedUser) {
        localStorage.setItem("loggedUser", JSON.stringify(loggedUser));
      } else {
        localStorage.removeItem("loggedUser");
      }
    }
  }, [loggedUser]);

  const validateToken = async () => {
    try {
      // Replace this with the actual endpoint and method to validate the token
      const response = await fetch("/api/validate-token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: jwtToken }),
      });

      // If the response is OK, the token is valid, so we can set `isLogged` to true
      if (response.ok) {
        setIsLogged(true);
      } else {
        // If the response is not OK, the token is not valid. We should clear the token and set `isLogged` to false
        setJwtToken("");
        setIsLogged(false);
      }
    } catch (error) {
      // If the request fails, we should also clear the token and set `isLogged` to false
      console.error("Failed to validate token:", error);
      setJwtToken("");
      setIsLogged(false);
    }
  };

  const handleSetNewUser: React.Dispatch<React.SetStateAction<User>> = (
    value
  ) => {
    const newUSerToAdd = typeof value === "function" ? value(newUser) : value;

    // Save the new client data to localStorage
    localStorage.setItem("newUser", JSON.stringify(newUSerToAdd));

    // Update the client state
    setNewUser(newUSerToAdd);
  };

  const login = async (username: string, password: string, role: string) => {
    try {
      const response = await userLogin(username, password);

      if (response.status === 200) {
        // You should replace 'user' with the actual key for the user in the response
        const loggedUserData: LoginUserResponce = response.data;
        localStorage.setItem("loggedUser", JSON.stringify(loggedUserData));
        console.log(loggedUserData);
        setLoggedUser(loggedUserData);
        const token = response.data.data.Token;
        const userType = response.data.data.Role;
        const userName = response.data.data.Name;
        if (userType.toLocaleLowerCase() === role.toLowerCase()) {
          setIsLogged(true);
          setUserName(userName);
          setJwtToken(token);
          setIsUserAdmin(userType === "admin"); // Set isAdmin true if the role is 'Admin'
          setLoginError("");
          setShowLoginLoadingScreen(false)
          return userType; // Add this line
        } else {
          setLoginError("The role selected does not match the user's role.");
          setJwtToken("");
          setShowLoginLoadingScreen(false)
          setLoggedUser(EmptyLoggedUser)
          localStorage.clear();
          return null;
          
        }
      } else {
        setLoginError("Failed to login.");
        setJwtToken("");
        setShowLoginLoadingScreen(false)
        setLoggedUser(EmptyLoggedUser)
        localStorage.clear();
      }
    } catch (error) {
      setLoginError("Failed to login.");
      setJwtToken("");
      console.error(error);
      localStorage.clear();
      setLoggedUser(EmptyLoggedUser)
      setShowLoginLoadingScreen(false)
    }
  };

  const logout = () => {
    // Clear local variables
    setIsLogged(false);
    setIsUserAdmin(false);
    setLoginError("");
    setUserName("");
    setJwtToken("");
    setLoggedUser(EmptyLoggedUser); // Assuming EmptyLoggedUser is the initial state of a logged-out user
    setUserUpdated(false);

    // Clear entire local storage
    localStorage.clear();
  };

  return (
    <UserContext.Provider
      value={{
        isLogged,
        isUserAdmin,
        loginError,
        userName,
        jwtToken,
        currentPage,
        newUser,
        userUpdated,
        setUserUpdated,
        setNewUser: handleSetNewUser,
        setCurrentPage,
        setLoginError,
        setIsUserAdmin,
        setIsLogged,
        setUserName,
        setJwtToken,
        login,
        userToUpdate,
        setUserToUpdate,
        loggedUser,
        setLoggedUser,
        logout,
        showLoginLoadingScreen,setShowLoginLoadingScreen
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
