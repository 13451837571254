import React from 'react'
import { Area } from '../../../../../_models/Report.model'
import { Col, Container, Row } from 'react-bootstrap'
import { ReportSubAreaView } from './ReportSubAreaView'
import { useReportContext } from '../../../../../_context/ReportContext'
import { DomesticReportScoreOutput } from './DomesticReportSpecificElements/DomesticReportScoreOutput'
import { DomesticAreaAndItemView } from './DomesticReportSpecificElements/DomesticAreaAndItemView'


interface ReportAreaViewProps{
    Areas:Area[]
}

export const ReportAreaView:React.FC<ReportAreaViewProps> = ({Areas}) => {
    const {currentViewingReport} = useReportContext()
  const area_based_scoring_types =
  process.env.REACT_APP_AREA_BASED_SCORING_TYPES?.split(",");

  const hideSectionForDomesticReports = !area_based_scoring_types?.includes(currentViewingReport.Client.Type.toString())

  return (
    <>
    {Areas.map(area => (
        <Row className='areaContainer'>
            <Col sm={hideSectionForDomesticReports ? 12 : 8}>
            <h3 className='reportAreaName'>
            {area.Name}
        </h3>
            {!hideSectionForDomesticReports && <DomesticAreaAndItemView subAreas={area.SubAreas}/>}
            </Col>
        <Col sm={hideSectionForDomesticReports ? 12 : 4}>
            <Container>
            {!hideSectionForDomesticReports && <DomesticReportScoreOutput SubAreas={area.SubAreas}/>}
            {hideSectionForDomesticReports && <ReportSubAreaView SubAreas={area.SubAreas}/>}
            </Container>
        </Col>
         {!hideSectionForDomesticReports && <hr className='mt-4'/>}
        </Row>
    ))}
    </>
    )
}
