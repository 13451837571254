import React, { useEffect, useState } from "react";
import { useReportContext } from "../../../_context/ReportContext";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import { Report } from "../../../_models/Report.model";
import { ReportViewModal } from "./ReportTemplate/ReportViewModal";
import { useClientContext } from "../../../_context/ClientContext";
import { ReportsTable } from "../../../htmlComponents/ReportsTable";

export const ClientReports = () => {
  const {
    filteredInspectionReportsForSelectedClient,
    filteredInternalReportsForSelectedClient,
    setCurrentViewingReport,
  } = useReportContext();
  const [viewReport, setViewReport] = useState<boolean>(false);
  const { client } = useClientContext();

  const viewReportHandler = (report: Report) => {
    setCurrentViewingReport(report);
    setViewReport(true);
  };

  //There are no reports under this client

  return (
    <Container className="mt-4">
      <Row className="mb-4">
        <Col sm={12}>
          <span>
            <h5>{client.Name}</h5>
          </span>
        </Col>
        <Col>{client.Email}</Col>
      </Row>

      <Row>
        <Tabs
          defaultActiveKey="inspectionReports"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="inspectionReports" title="Inspection Reports">
            <ReportsTable
              reports={filteredInspectionReportsForSelectedClient}
              noResultsMessage="There are no inspection reports under this client"
              viewReportHandler={viewReportHandler}
            />
          </Tab>
          <Tab eventKey="internalReports" title="Internal Reports">
            <ReportsTable
              reports={filteredInternalReportsForSelectedClient}
              noResultsMessage="There are no internal reports under this client"
              viewReportHandler={viewReportHandler}
            />
          </Tab>
        </Tabs>
      </Row>

      <ReportViewModal viewReport={viewReport} setViewReport={setViewReport} />
    </Container>
  );
};
