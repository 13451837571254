import React from 'react'
import { SubArea } from '../../../../../../_models/Report.model'
import { Col, Row } from 'react-bootstrap'

interface DomesticAreaAndItemViewProps{
    subAreas:SubArea[]
}

export const DomesticAreaAndItemView:React.FC<DomesticAreaAndItemViewProps> = ({subAreas}) => {
  return (
    <Row>
    {subAreas.map((subArea,index) => (
        <>
        <Col sm={12} key={`subArea_${index}`} className='mt-2'>
        <span className='generalSubTitle'>
        {subArea.Name}
        </span>
        </Col>
        <Row>
        <Col sm={1}></Col>
        <Col sm={11}>
        <Row>
            {subArea.Items.map((item,index) => (
                <Col sm={12} className='mt-2' key={`item_${index}`}>
                    <span >
                <li className='generalItem'>
                    {item.Name}
                    </li>
                    </span>
                </Col>
                
            ))}
            
        </Row>
        </Col>
        </Row>
        </>
    ))}
    </Row>
  )
}
