import React from "react";
import { Modal, Button } from "react-bootstrap";

interface SuccessModalProps {
  show: boolean;
  onHide: () => void;
  successMessage:string ;
}

const SuccessfullSubmission: React.FC<SuccessModalProps> = ({ show, onHide,successMessage }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Success</Modal.Title>
      </Modal.Header>
      <Modal.Body>{successMessage}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuccessfullSubmission;
