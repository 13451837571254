import { Text, View } from '@react-pdf/renderer';
import {styles} from './PDFReportCSS'
import { Area, SubArea } from '../../../_models/Report.model';
import { PDFReportItem } from './PDFReportItems';

interface PDFReportSubAreaProps {
    subAreas: SubArea[];
  }
  

export const PDFReportSubAreas:React.FC<PDFReportSubAreaProps> = ({
    subAreas
  }) => {
  return (
<>
        {subAreas.map( (subArea,index) => (
            <View key={`subAreaDetails${index}`}  wrap={false}>
          <View style={styles.subAreaSection}>
              <Text style={styles.subAreaTitle}>{subArea.Name}</Text>
           </View>
           <PDFReportItem items={subArea.Items}/>
          </View>
        ))}
          
        </>
  );
};

