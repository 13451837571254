import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { SubArea } from '../../../../_models/Report.model'
import { ClientItems } from './ClientItems'
import { useReportContext } from '../../../../_context/ReportContext'

interface ClinetSubAreasProps{
    subAreas:SubArea[]
    selectedAreaIndex?:number
}

export const ClientSubAreas:React.FC<ClinetSubAreasProps> = ({subAreas,selectedAreaIndex}) => {


    return (
        <Row>
            {subAreas.map((subArea,index) => (
                <>
                <Col sm={12} key={`subArea_${index}`} className='mt-2'>
                <span className='generalSubTitle'>
                {subArea.Name}
                </span>
                </Col>
                <Col sm={12}>
                    <ClientItems key="client_items" itemList={subArea.Items} selectedAreaIndex={selectedAreaIndex} subAreaIndex={index} />
                </Col>
                </>
            ))}


        </Row>
    )
}
