import { PDFReportHeader } from './PDFReportHeader';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import {styles} from './PDFReportCSS'
import { PDFReportAreas } from './PDFReportArea';
import { PDFReportRemarks } from './PDFReportRemarks';
import { PDFReportSignatures } from './PDFReportSignatures';
import { Report } from '../../../_models/Report.model';



interface PDFReportProps{
  currentViewingReport:Report
}

export const PDFReport:React.FC<PDFReportProps> = ({currentViewingReport}) => {



  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <PDFReportHeader client={currentViewingReport.Client} totalScore={currentViewingReport.TotalScore.toString()} reportCreateDate={currentViewingReport.ReportedDate}  />
            <PDFReportAreas client={currentViewingReport.Client} areas={currentViewingReport.Areas}/>
            <PDFReportRemarks client={currentViewingReport.Client} CNARemarks={currentViewingReport.CNARemarks} ClientRemarks={currentViewingReport.ClientRemarks} FaultReports={currentViewingReport.FaultReport}/>
            <PDFReportSignatures  Client={currentViewingReport.Client} CNASignature={currentViewingReport.QASignature} ClientSignature={currentViewingReport.ClientSignature} ClientConcent={currentViewingReport.ClientConcent} InspectedBy={currentViewingReport.InspectedBy}  />
      </Page>
    </Document>
  );
};

