import React, { useEffect } from "react";
import { Button, Form, Container, Row, Col, Accordion } from "react-bootstrap";
import {
  validateName,
  validateEmail,
  validateAddressLines,
  validatePostCode,
  validateContactNumber,
} from "../../Validations";
import { ClientBasicDetails } from "./AddClientsComponents/ClientBasicDetails";
import { useClientContext } from "../../../_context/ClientContext";
import { ClientAddressDetails } from "./AddClientsComponents/ClientAddressDetails";
import { ClientAdminDetails } from "./AddClientsComponents/ClientAdminDetails";
import { useUserContext } from "../../../_context/UserContext";
import { FaClipboardList, FaClipboardUser, FaUserPlus, FaUserGear,FaUserPen } from "react-icons/fa6";
import { ClientCleanerDetails } from "./AddClientsComponents/ClientCleanerDetails";
import EmptyClient from "../../../_resources/JsonData/EmptyClient.json";
import { addClient, getAllClients } from "../../../_utils/resourceAPI";
import { Client } from "../../../_models/Client.model";
import { AddClientSuccessModal } from "./AddClientsComponents/AddClientSuccessModal";
import { createDateISO, expiredDateISO } from "../../../_constrains/dates";
import { ClientTypeDetails } from "./AddClientsComponents/ClientTypeDetails";
import { ClientReportingDetails } from "./AddClientsComponents/ClientReportingUserDetails";

export const AddClients = () => {
  const { client, setClient,setAllClients,setClientAdded } = useClientContext();
  const { setCurrentPage } = useUserContext();

  const isFormValid = () => {
    return (
      !validateName(client.Name, true) &&
      !validateEmail(client.Email, true) &&
      !validateAddressLines(client.Address.Line1, true) &&
      !validateAddressLines(client.Address.Line2, false) &&
      !validatePostCode(client.Address.Postcode.toString(), true)
    );
  };


  const fetchClients = () => {
    getAllClients()
      .then((res) => {
        const allClients: Client[] = res.data;
        setAllClients(allClients);
      })
      .catch((error) => {
        console.log("Failed to fetch cleaners");
      });
  };


  useEffect(() => {
    console.log("load Add client page")
    setCurrentPage(`Add Client`);
    if(client.Id){
      setClient(EmptyClient)
    } 
  }, []);

  const handleFormSubmit = () => {
    const newClientData = {
      ...client,
      CreateDate: createDateISO,
      ExpiredDate: expiredDateISO,
    }
    console.log(newClientData)
    addClient(newClientData).then((res) => {
      fetchClients();
      setClient(EmptyClient);
      setClientAdded(true)
      console.log(`client added ${res.status.toString()}`)
    })
    .catch((error) => {
      console.log(error);
    });
  };

  return (
    <Container className="p-4">
      <Form>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h5>
                <FaClipboardList /> Client Contact Infomation
              </h5>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col sm={12}>
                  <ClientBasicDetails
                    clientNameValidator={validateName}
                    clientemailValidator={validateEmail}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}></Col>
                <Col sm={6}>
                  <ClientAddressDetails
                    addressValidator={validateAddressLines}
                    postCodeValidator={validatePostCode}
                  />
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <h5>
                <FaUserGear /> Select Client Type
              </h5>
            </Accordion.Header>
            <Accordion.Body>
                <ClientTypeDetails/>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <h5>
                <FaClipboardUser /> Client Admin Infomation
              </h5>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <ClientAdminDetails
                  adminEmailValidator={validateEmail}
                  adminContactValidator={validateContactNumber}
                  adminNameValidator={validateName}
                />
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <h5>
                <FaUserPlus /> Assigned Cleaner Infomation
              </h5>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <ClientCleanerDetails />
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <h5>
                <FaUserPen /> Supervisor Details
              </h5>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <ClientReportingDetails/>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Row>
          <Col className="">
            <Button
              className="btn-success w-100 mt-2"
              onClick={handleFormSubmit}
              disabled={!isFormValid()}
            >
              Add Client
            </Button>
          </Col>
        </Row>
      </Form>
      <AddClientSuccessModal/>
    </Container>
  );
};
