import React from "react";
import AWS from "aws-sdk";
import { Container, Row, Col } from "react-bootstrap";
import { FaArrowCircleUp } from "react-icons/fa";


AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

export interface S3UploaderProps {
  image: string;
  imageName: string;
  bucketName: string;
  folderName: string;
  s3ClientName:string;
  onUploadSuccess?: (url: string) => void;
  onUploadError?: (error: Error) => void;
}

const S3Uploader: React.FC<S3UploaderProps> = ({
  image,
  imageName,
  bucketName,
  folderName,
  s3ClientName,
  onUploadSuccess,
  onUploadError,
}) => {
  const base64ToBlob = (base64: string) => {
    const byteString = atob(base64.split(",")[1]);
    const mimeString = base64.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type: mimeString });
  };

  const uploadImage = async () => {
    const s3 = new AWS.S3();
    const keyName = `${s3ClientName}/${folderName}/${imageName}`;
    const binaryImage = base64ToBlob(image);
    const params = {
      Bucket: bucketName,
      Key: keyName,
      Body: binaryImage,
      ContentType: "image/jpeg",
      ACL: "public-read",
    };

    try {
      const response = await s3.upload(params).promise();
      console.log("Upload successful:", response.Location);
      if (onUploadSuccess) {
        onUploadSuccess(response.Location);
      }
    } catch (error) {
      console.error("Upload failed:", error);
      if (onUploadError) {
        onUploadError(error as Error);
      }
    }
  };

  React.useEffect(() => {
    uploadImage();
  }, []);

  return <Container className="text-center">
    <Row key={"s3Uploader"}>
      <Col>
      <FaArrowCircleUp size={3} className="text-success"/>
      </Col>
    </Row>
    </Container>;
};

export default S3Uploader;
