import React,{useState} from "react";
import { Button, Col, Container, Row, Modal } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import { useUserContext } from "../../_context/UserContext";
import { FaSignOutAlt } from "react-icons/fa";


export const ReportLayout = () => {
  const { currentPage,loggedUser,logout } = useUserContext();
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleCloseLogoutModal = () => setShowLogoutModal(false);
  const handleShowLogoutModal = () => setShowLogoutModal(true);
  const handleConfirmLogout = () => {
    logout();
    handleCloseLogoutModal();
  };




  return (
    <Container fluid>

      <Row className="headerConainer mb-4">

        <Col xs={6} sm={6}>
          <h1>{currentPage}</h1>
        </Col>
        <Col xs={4} sm={5} className="userName">
        <p className="text-end"> {loggedUser?.data ? loggedUser.data.Name : "Loading..."}
</p>
        </Col>
        <Col xs={2} sm={1} className="mb-2">
        <Button className="w-100" variant="outline-danger" onClick={handleShowLogoutModal}><FaSignOutAlt/></Button>
        </Col>
        <Modal show={showLogoutModal} onHide={handleCloseLogoutModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to log out?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseLogoutModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
      </Row>




      
      <Outlet/>
    </Container>
  );
};
