import React from 'react'
import { useClientContext } from '../../../../_context/ClientContext';
import { Button, Col, Row } from 'react-bootstrap';
import { ClientType } from '../../../../_models/Client.model';
import '../../../../htmlComponents/customStyles/clientTypeButtons.css'

export const ClientTypeDetails = () => {
    const { client, setClient } = useClientContext();

    
    const handleClientTypeChange = (value: ClientType) => {
        setClient(client => ({...client,Type:value}))
    }
      


  return (
    <Row>
      <Col sm={12}>
        {Object.keys(ClientType).filter((key) => isNaN(Number(key))).map((key) => {
          const clientTypeEnum = ClientType[key as keyof typeof ClientType];
          const isSelected = client.Type === clientTypeEnum;
          const buttonVariant = isSelected ? 'primaryButton' : 'secondaryButton';

          return (
            <Button
              key={key}
              className={buttonVariant}
              onClick={() => handleClientTypeChange(clientTypeEnum)}
            >
              {key}
            </Button>
          );
        })}
      </Col>
    </Row>
  )
}
