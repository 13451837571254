import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Row,
  FormControl,
  Modal,
} from "react-bootstrap";
import { Item } from "../../../../_models/Report.model";
import { useReportContext } from "../../../../_context/ReportContext";
import { ClientItemScoring } from "./ClientItemScoring";
import { FaCamera } from "react-icons/fa";
import Camera from "../../../../_camera/Camera";
import { ClientItem } from "./ClientItem";

interface ClinetItemsProps {
  itemList: Item[];
  subAreaIndex?: number;
  selectedAreaIndex?: number;
}

const area_based_scoring_types =
  process.env.REACT_APP_AREA_BASED_SCORING_TYPES?.split(",");

export const ClientItems: React.FC<ClinetItemsProps> = ({
  itemList,
  selectedAreaIndex,
  subAreaIndex,
}) => {
  const { newReport, setNewReport } = useReportContext();
  const [imageList, setImageList] = useState<string[]>([]);
  const [showCameraModal, setShowCameraModal] = useState(false);
  const items: Item[] =
    selectedAreaIndex !== undefined && subAreaIndex !== undefined
      ? newReport.Areas[selectedAreaIndex]?.SubAreas[subAreaIndex]?.Items || []
      : [];

  const handleCapture = (imageData: string) => {
    if (!imageList.includes(imageData)) {
      setImageList((prevImages) => [...prevImages, imageData]);
    }
  };

  const handleScoreUpdate = (itemIndex: number, newScore: number) => {
    let scoreSum = 0;
    let itemCount = 0;

    const updatedAreas = newReport.Areas.map((area, areaIndex) => {
      if (areaIndex === selectedAreaIndex) {
        const updatedSubAreas = area.SubAreas.map((subArea, sIndex) => {
          if (sIndex === subAreaIndex) {
            const updatedItems = subArea.Items.map((item, iIndex) => {
              // Update the score for the relevant item
              const updatedItem = iIndex === itemIndex ? { ...item, Mark: newScore } : item;

              // Add to score sum and count if score is greater than 0
              if (updatedItem.Mark > 0) {
                scoreSum += updatedItem.Mark;
                itemCount++;
              }

              return updatedItem;
            });
            return { ...subArea, Items: updatedItems };
          }
          return subArea;
        });
        return { ...area, SubAreas: updatedSubAreas };
      } else {
        // For areas not being updated, just add their scores to the total
        area.SubAreas.forEach(subArea => {
          subArea.Items.forEach(item => {
            if (item.Mark > 0) {
              scoreSum += item.Mark;
              itemCount++;
            }
          });
        });
        return area;
      }
    });

    // Calculate the percentage (assuming each item can score a maximum of 5)
    const totalPercentage = itemCount > 0 ? (scoreSum / (itemCount * 5)) * 100 : 0;
    const roundedTotalPercentage = parseFloat(totalPercentage.toFixed(1));

    // Update the newReport object with the updated areas and the new total score
    setNewReport(prevReport => ({
      ...prevReport,
      Areas: updatedAreas,
      TotalScore: roundedTotalPercentage
    }));
};

  const handleCommentUpdate = (itemIndex: number, newComment: string) => {
    const updatedAreas = newReport.Areas.map((area, areaIndex) => {
      if (areaIndex === selectedAreaIndex) {
        const updatedSubAreas = area.SubAreas.map((subArea, sIndex) => {
          if (sIndex === subAreaIndex) {
            const updatedItems = subArea.Items.map((item, iIndex) => {
              if (iIndex === itemIndex) {
                return { ...item, Comment: newComment };
              }
              return item;
            });
            return { ...subArea, Items: updatedItems };
          }
          return subArea;
        });
        return { ...area, SubAreas: updatedSubAreas };
      }
      return area;
    });

    setNewReport({ ...newReport, Areas: updatedAreas });
  };


  const handleImageUpdate = (itemIndex:number, imageArray:string[]) => {
    const updatedAreas = newReport.Areas.map((area, areaIndex) => {
      if (areaIndex === selectedAreaIndex) {
        const updatedSubAreas = area.SubAreas.map((subArea, sIndex) => {
          if (sIndex === subAreaIndex) {
            const updatedItems = subArea.Items.map((item, iIndex) => {
              if (iIndex === itemIndex) {
                return { ...item, Images: imageArray };
              }
              return item;
            });
            return { ...subArea, Items: updatedItems };
          }
          return subArea;
        });
        return { ...area, SubAreas: updatedSubAreas };
      }
      return area;
    });

    setNewReport({ ...newReport, Areas: updatedAreas });
  }

  return (
    <Container>
      {area_based_scoring_types?.includes(newReport.Client.Type.toString()) ? (
        <Row>
          {items.map((item, index) => (
            <Col sm={12} className="mt-2" key={`item_${index}`}>
              <span>
                <li className="generalItem">{item.Name}</li>
              </span>
            </Col>
          ))}
        </Row>
      ) : (
        <>
          {items.map((item, index) => (
            <ClientItem key={item.Name} item={item} itemIndex={index} handleCommentUpdate={handleCommentUpdate} handleScoreUpdate={handleScoreUpdate} handleImageUpdate={handleImageUpdate}/>
          ))}
        </>
      )}

      
    </Container>
  );
};
