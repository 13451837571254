import axios from 'axios' 
import { HOST_NAME } from '../_constrains';
import { Client } from '../_models/Client.model';
import { User } from '../_models/User.model';
import { Cleaner } from '../_models/Cleaner.model';
import { Report } from '../_models/Report.model';



const getHTTPClient = () => {
    let httpClient = axios.create({
      baseURL: HOST_NAME
    });

    httpClient.defaults.headers.post["Content-Type"] = "application/json";
    const token = localStorage.getItem("token");
    if (token) {
      httpClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    httpClient.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        return Promise.reject(error);
      }
    );

    return httpClient;
  }

export const userLogin = (username:string, password:string) => {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    return getHTTPClient().post('v1/login', formData, config);
  }; 


export const getAllCleaners = () => {
  return getHTTPClient().get("v1/cleaners")
}

export const updateCleaners = (id: string, cleaner: Cleaner) => {
  return getHTTPClient().put(`v1/cleaners/${id}`, cleaner);
};



export const getAllClients = () => {
  return getHTTPClient().get("v1/users")
}

export const updateClient = (id: string, client: Client) => {
  return getHTTPClient().put(`v1/users/${id}`, client);
};


export const deleteClient = (id: string) => {
  return getHTTPClient().delete(`v1/users/${id}`);
};


export const addClient = (client:Client) => {
  return getHTTPClient().post('v1/users',client)
}


export const getAllSystemUsers = () => {
  return getHTTPClient().get("v1/system-users")
}

export const updateSystemUsers = (id: string, user: User) => {
  return getHTTPClient().put(`v1/system-users/${id}`, user);
};


export const getAllReports = async () => {
  console.log('getting reports')
  return await getHTTPClient().get("v1/files")
}


export const saveReport = (report:Report) => {
  return getHTTPClient().post('v1/files',report)
}   


export const submitUser = (user:User) => {
  return getHTTPClient().post('v1/system-users',user)
} 

export const submitCleaner = (cleaner:Cleaner) => {
  return getHTTPClient().post('v1/cleaners',cleaner)
} 

export const deleteCleaner = (id: string) => {
  return getHTTPClient().delete(`v1/cleaners/${id}`);
};