import React, { useState, useEffect } from "react";
import { useUserContext } from "../../_context/UserContext";
import { useNavigate } from "react-router-dom";
import { mandatoryValueValidator } from "./Validations";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { AlertComponent } from "../../_modals_and_Notifications/AlertComponent";
import { FormSelect } from "../../htmlComponents/FormSelect";
import { FormInput } from "../../htmlComponents/FormInput";
import { LoadingSpinnerModal } from "../../_modals_and_Notifications/LoadingSpinnerModal";
import CNALOGO from '../../_resources/Images/logo.png'
import { useLoggedInUserContext } from "../../_context/LoggedInUserContext";


export const Login = () => {
  const [username, setUsername] = useState(""); 
  const [password, setPassword] = useState("");
  const [role, setRole] = useState(""); 
  const { loginError,login,isLogged,isUserAdmin,showLoginLoadingScreen,setShowLoginLoadingScreen } = useUserContext();
  const navigate = useNavigate();

  
  const isFormValid = () => {
    return (
      !mandatoryValueValidator(username, true) &&
      !mandatoryValueValidator(password, true) &&
      !mandatoryValueValidator(role, true)
    );
  };

  
  useEffect(() => {
    // Handle redirection based on user's role and logged-in state
    if (isLogged) {
      if (isUserAdmin) {
        navigate('/admin');
      } else {
        navigate('/reports');
      }
    }
  }, [isLogged, isUserAdmin, navigate]);
  

// need to add a useEffect to check tge local user details ( if the user is logged or not ) 
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isFormValid()) {
      setShowLoginLoadingScreen(true);
      try{        
        login(username, password, role).then((userType) => {
          console.log(userType)
          if (userType === "admin") {
            navigate("/admin");
          } else if (userType === "system user") {
            navigate("/reports");
          }
        });
      }catch(error){
        console.error("Login failed:", error);
        setShowLoginLoadingScreen(false);
        navigate("/")
      }
      
    }
  };

 

  return (
    <Container>
      <Row className="vh-100">
        <Col sm={6} className="p-4 loginImageContainer">
          <img src={CNALOGO} className="loginImage"/>
        </Col>
        <Col sm={6} xs={12} className="p-4 loginFormContainer">
          <Container fluid>
            <Row>
        <Col xs={12} sm={12} className="mb-1">
          
          
          {loginError && (
            <AlertComponent
              className="mb-2"
              key="loginError"
              variant="danger"
              message={loginError}
            />
          )}
          </Col>
          <Col xs={12} sm={12} className="mb-2">
          <Form noValidate onSubmit={handleSubmit} className="w-100">            
            <FormSelect
              id="userTypeSelect"
              label="Login as"
              optionAndValue={{ Admin: "admin", User: "system user" }}
              required={true}
              key={"userTypeSelect"}
              onChange={setRole}
              value={role}
              validator={mandatoryValueValidator}
            />
            
            <FormInput
              id="UserName"
              label="User Name"
              type="text"
              value={username}
              required={true}
              onChange={setUsername}
              validator={mandatoryValueValidator}
            />
            <FormInput
              id="Password"
              label="Password"
              type="password"
              value={password}
              required={true}
              onChange={setPassword}
              validator={mandatoryValueValidator}
            />
            <Button
              className="btn-success w-100 mt-2"
              type="submit"
              disabled={!isFormValid()}
            >
              Login
            </Button>
          </Form>
          </Col>
          </Row>
          </Container>
        </Col>
      </Row>
      <LoadingSpinnerModal ShowModal={showLoginLoadingScreen}/>
    </Container>
  );
};
