import React, { useState } from "react";
import { Button, Col, Container, Row, Spinner, Table } from "react-bootstrap";
import { Navigate, Outlet } from "react-router-dom";
import { Client } from "../../../_models/Client.model";
import "../../../htmlComponents/customStyles/clientFilterStyles.css";
import { ClientSearchAndFilter } from "../../../htmlComponents/clientSearchAndFilter";
import { useClientContext } from "../../../_context/ClientContext";
import { useNavigate } from "react-router-dom";
import { useCreateReportNotificationContext } from "../../../_context/CreateReportNotificationContext";
import { CreateReportAlerts } from "./CreateReportAlerts";
import { useSetReportAndClient } from "../../../_hooks/SetReportOnClients";
import { useReportContext } from "../../../_context/ReportContext";

export const CreateReportSelectClientLayout = () => {
  const [filteredClients, setFilteredClients] = useState<Client[]>([]);
  const [noDataOrLoadingData, setNoDataOrLoadingData] = useState<string>("");
  const { notification, displayNotification, setDisplayNotification } =
    useCreateReportNotificationContext();
  const { setClientForReporting } = useClientContext();
  const navigate = useNavigate();
  const setReportAndClient = useSetReportAndClient();
  const {setIsNewReport} = useReportContext()

  const handleClientReportSelect = (client: Client) => {
    console.log("Selected Client:", client);
    setReportAndClient(client);
    setClientForReporting(client);
    setDisplayNotification(false);
    setIsNewReport(true)
    navigate("/reports/create_new_report");
  };

  return (
    <Container>
      <CreateReportAlerts
        alertMessage={notification}
        displayAlert={displayNotification}
        key="createReportAlert"
      />
      <ClientSearchAndFilter
        setFiltererdClients={setFilteredClients}
        setDataStatus={setNoDataOrLoadingData}
      />
      {filteredClients.length > 0 ? (
        <Row>
          <Col sm={12} className="overFlowScroll">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredClients.map((client) => {
                  return client ? (
                    <tr key={client.Id}>
                      <td>{client.Name}</td>
                      <td>{client.Email}</td>
                      <td>
                        <Button
                          className="w-100"
                          onClick={() => {
                            handleClientReportSelect(client);
                          }}
                        >
                          Select Client
                        </Button>
                      </td>
                    </tr>
                  ) : null;
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col className="text-center">
            {noDataOrLoadingData === "Loading Clients" ? (
              <Spinner animation="border" role="status" />
            ) : (
              <>{noDataOrLoadingData}</>
            )}
          </Col>
        </Row>
      )}
      <Row>
        <Col sm={12}>
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};
