import React from 'react'
import { Modal } from 'react-bootstrap'

import { FaUserCheck } from 'react-icons/fa6'
import { useUserContext } from '../../../../_context/UserContext'

export const UserUpdatedSuccessModal = () => {

    const{setUserUpdated,userUpdated} = useUserContext()

  return (
    <Modal
    show={userUpdated}
    size="xl"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    onHide={() => setUserUpdated(false)}
    >
    
        <Modal.Header  closeButton onHide={() => setUserUpdated(false)}>
           <Modal.Title><FaUserCheck/> Success</Modal.Title> 
        </Modal.Header>
        <Modal.Body>
            User Updated Successfully
        </Modal.Body>
    </Modal>
  )
}
