import { Console } from "console";

const currentDate = new Date();
const expiredDate = new Date(
  currentDate.setFullYear(currentDate.getFullYear() + 30)
);

export const parseDate = (dateString:string) => {
  const dateParts = dateString.split('-');
  const year = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1;
  const day = parseInt(dateParts[2], 10);

  // Create a date in UTC
  return new Date(Date.UTC(year, month, day));
};



export const formatDateForInput = (isoDateString:string) => {
  if (!isoDateString) return '';

  const date = new Date(isoDateString);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // January is 0 in UTC
  const year = date.getUTCFullYear();

  return `${year}-${month}-${day}`;
};


export const createDateISO = currentDate.toISOString();
export const expiredDateISO = expiredDate.toISOString();

