import { Client } from "../../../_models/Client.model";
import { Text, View, Image } from "@react-pdf/renderer";
import { styles } from "./PDFReportCSS";
import CNABanner from "../../../_resources/Images/CNANewBanner.jpg";

interface PDFReportHeaderProps {
  client: Client;
  totalScore: string;
  reportCreateDate: string;
}

export const PDFReportHeader: React.FC<PDFReportHeaderProps> = ({
  client,
  totalScore,
  reportCreateDate,
}) => {


  const area_based_scoring_types =
  process.env.REACT_APP_AREA_BASED_SCORING_TYPES?.split(",");
  const hideSectionForDomesticReports = !area_based_scoring_types?.includes(client.Type.toString())

  return (
    <>
      <View key={"headerDetails"}>
        <View style={styles.headerTitleSection}>
          <Text style={{...styles.heading,fontWeight:100,color:"darkblue"}}>{hideSectionForDomesticReports ? `CNA Inspection Report` : 'Job Completion Report'}</Text>
        </View>
        <View style={styles.headerImageSection}>
          <Image src={CNABanner} />
        </View>
        <View style={styles.headerContentSection}>
          <View style={{...styles.row,marginTop:"10px"}}>
          <View style={{...styles.col,width:hideSectionForDomesticReports ? "100%" :"80%"}}>
            
                <View style={styles.col}>
                    <Text style={{ ...styles.headerContainerTextFullColumn }}>
                        {client.Name}
                    </Text>
                </View>
                <View style={styles.col}>
                    <Text style={{ ...styles.headerContainerTextFullColumn,fontSize:"10px",color:"gray" }}>
                    {`${client.Address.Line1.toUpperCase()},`}
                    {client.Address.Line2 && `${client.Address.Line2.toUpperCase()},`}
                    {`${client.Address.Suburb.toUpperCase()},`}
                    {client.Address.Postcode}
                    </Text>
                </View>
                <View style={styles.col}>
                <Text style={{ ...styles.headerContainerTextFullColumn,fontSize:"10px",color:"gray" }}>
                  {reportCreateDate}
                </Text>
                </View>

            
          </View>
          {hideSectionForDomesticReports && 
          <View style={{...styles.col,width:"20%",justifyContent:"flex-end",alignItems:"flex-end"} }>
         
                    <Text style={{fontSize:"14px"}}>
                        {`Score : ${totalScore}%`}
                    </Text>

          </View>}

          </View>

        </View>
      </View>
    </>
  );
};
