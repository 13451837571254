import { Client, ClientType } from '../_models/Client.model';
import School from "../_resources/JsonData/ReportTypes/School.json";
import KinderGarden from "../_resources/JsonData/ReportTypes/KinderGarden.json";
import AirBNB from "../_resources/JsonData/ReportTypes/AirBnB.json";
import Commercial from "../_resources/JsonData/ReportTypes/Commercial.json";
import Domestic from "../_resources/JsonData/ReportTypes/Domestic.json";
import MCH from "../_resources/JsonData/ReportTypes/MCH.json";
import { useReportContext } from '../_context/ReportContext';
import { Report } from '../_models/Report.model';


export const useSetReportAndClient = () => {
  const { setNewReport } = useReportContext();

  const setReportAndClient = (client: Client) => {
    let newReportTemplate:Report;

    switch (client.Type) {
      case ClientType.School:
        newReportTemplate = School;
        break;
      case ClientType.Kindergarden:
        newReportTemplate = KinderGarden;
        break;
      case ClientType.AirBNB:
        newReportTemplate = AirBNB;
        break;
      case ClientType.Commercial:
        newReportTemplate = Commercial;
        break;
      case ClientType.Domestic:
        newReportTemplate = Domestic;
        break;
      case ClientType.MCH:
        newReportTemplate = MCH;
        break;
      default:
        newReportTemplate = Commercial; // or another default template
    }

    setNewReport((report) => ({
        ...report,
        ...newReportTemplate,
        Client: client
      }));
        };

  return setReportAndClient;
};
