import React, { ChangeEvent } from 'react';
import { useReportContext } from '../../../../_context/ReportContext';
import { Col, Container, Row, Form, Button } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

export const ClientAndClientComments = () => {
  const { newReport, setNewReport } = useReportContext();
  const navigate = useNavigate()

  const handleClientRemarksChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNewReport({ ...newReport, ClientRemarks: event.target.value });
  };

  const handleCNARemarksChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNewReport({ ...newReport, CNARemarks: event.target.value });
  };

  const handlePageSubmit = () => {
      navigate('/reports/create_new_report/signatures')
  };

  return (
    <Container>
      <Row>
        <Col sm={12}>
          <Form.Group className="mb-3" controlId="clientComments">
            <Form.Label className='generalSubTitle'>Client Comments</Form.Label>
            <Form.Control
              as="textarea"
              rows={8}
              placeholder='Client comments'
              value={newReport.ClientRemarks || ''}
              onChange={handleClientRemarksChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className='mt-4'>
          <Form.Group className="mb-3" controlId="cnaComments">
            <Form.Label className='generalSubTitle'>CNA Comments</Form.Label>
            <Form.Control
              as="textarea"
              rows={8}
              placeholder='CNA comments'
              value={newReport.CNARemarks || ''}
              onChange={handleCNARemarksChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Button className="w-100 mb-4" variant="warning" onClick={handlePageSubmit}>Move to client signatures <FaArrowRight/> </Button>
    </Container>
  );
};
