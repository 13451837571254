import React, { useState, useEffect } from "react";
import { Container, Button, Row, Col, Modal } from "react-bootstrap";
import { blobToBase64 } from "../_utils/utilFunctions";
import { useReportContext } from "../_context/ReportContext";

interface EmailSenderProps {
  pdfBlob: Blob | null;
  isEnabled:boolean
}

const EmailSenderClient: React.FC<EmailSenderProps> = ({ pdfBlob,isEnabled }) => {
  const { currentViewingReport } = useReportContext();
  const [recipientEmail, setRecipientEmail] = useState('');
  const [adminEmails, setAdminEmails] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  useEffect(() => {
    setRecipientEmail(currentViewingReport.Client.Email);
    if (currentViewingReport && currentViewingReport.Client && currentViewingReport.Client.Admins) {
      const emailList = currentViewingReport.Client.Admins.map(admin => admin.Email);
      setAdminEmails(emailList.join(', '));
    }
  }, [currentViewingReport]);

  const sendEmailWithPDF = async () => {
    if (!pdfBlob) return;

    setModalMessage('Sending email...');
    setShowModal(true);

    try {
      const pdfBase64 = await blobToBase64(pdfBlob);
      const cleanedBase64 = pdfBase64.replace(/^data:application\/pdf;base64,/, "");

      const emailParams = {
        toEmail: recipientEmail,
        ccEmail: adminEmails,
        subject: "Comprehensive Cleaning Report from CNA Cleaning Services",
        body: `
          <div style="font-family: Arial, sans-serif; color: #333;">
            <h1 style="color: #4F7D7A;">CNA Cleaning Services</h1>
            <p>Dear ${currentViewingReport.Client.Name},</p>
            <p>We hope this email finds you well. We are pleased to provide you with the attached cleaning report, detailing our most recent services at your premises.</p>
            <p>At CNA Cleaning Services, we strive for excellence and are committed to providing top-notch cleaning services tailored to your unique needs. We trust that this report will provide you with a comprehensive overview of the tasks completed and any recommendations for future cleaning sessions.</p>
            <p>If you have any questions, require further clarification, or wish to discuss any aspect of the report in detail, please do not hesitate to get in touch. Your feedback is invaluable to us, and we are always here to assist you.</p>
            <p>Thank you for choosing CNA Cleaning Services. We look forward to continuing to serve you with dedication and excellence.</p>
            <p>Warm regards,</p>
            <p>The CNA Cleaning Team</p>
          </div>`,
        attachment: cleanedBase64,        
      };

      const response = await fetch(process.env.REACT_APP_EMAIL_API_ENDPOINT || '', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailParams),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }

      const data = await response.json();
      console.log(data);
      setModalMessage('Email sent to client successfully!');
    } catch (error) {
      console.error('Error:', error);
      setModalMessage('Failed to send email. Please try again later.');
    }
  };

  return (
    <Container className="mt-2">
      <Row>
        <Col>
          <Button onClick={sendEmailWithPDF} className="w-100" variant="secondary" disabled={!pdfBlob || !isEnabled}>
            Email Report to Clients
          </Button>
        </Col>
      </Row>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Report Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default EmailSenderClient;
