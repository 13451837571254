import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { UserContextProvider } from "./_context/UserContext";
import { ClientContextProvider } from "./_context/ClientContext";
import { CleanerContextProvider } from "./_context/CleanerContext";
import { ReportContextProvider } from "./_context/ReportContext";
import { CreateReportNotificationContextProvider } from "./_context/CreateReportNotificationContext";
import { LoggedUserContextProvider } from "./_context/LoggedInUserContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <LoggedUserContextProvider>
    <UserContextProvider>
      <ClientContextProvider>
        <CleanerContextProvider>
          <ReportContextProvider>
            <CreateReportNotificationContextProvider>
          <App />
          </CreateReportNotificationContextProvider>
          </ReportContextProvider>
        </CleanerContextProvider>
      </ClientContextProvider>
    </UserContextProvider>
    </LoggedUserContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
