import React from 'react'
import { SubArea } from '../../../../_models/Report.model';
import { Text, View } from '@react-pdf/renderer';
import {styles} from '../PDFReportCSS'

interface PDFReportSubAreaProps {
    subAreas: SubArea[];
  }

export const PDFReportSubAreaAndItemView:React.FC<PDFReportSubAreaProps> = ({
    subAreas
}) => {
  return (
    <>
        {subAreas.map( (subArea,index) => (
            <View key={`subAreaDetails${index}`}  wrap={false}>
          <View style={styles.subAreaSection}>
              <Text style={styles.subAreaTitle}>{subArea.Name}</Text>
              {subArea.Items.map(Item => (
                <Text style={{fontSize:"10px", paddingRight:"4px"}}>{Item.Name}</Text>
              ))}
           </View>
          </View>
        ))}
          
        </>
  )
}
