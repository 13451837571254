import { useState, useEffect } from 'react';

export const useISOFormattedCurrentDate = () => {
  const [formattedDate, setFormattedDate] = useState('');

  useEffect(() => {
    const currentDate = new Date();
    const isoString = currentDate.toISOString();
    setFormattedDate(isoString);
  }, []);

  return formattedDate;
};

