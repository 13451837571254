
import { View, Text, Image, Link } from "@react-pdf/renderer";
import { styles } from "./PDFReportCSS";
import { Item } from "../../../_models/Report.model";
import starImg from "../../../_resources/Images/reportPDF/yellow.png";
import GrayStarImg from "../../../_resources/Images/reportPDF/gray.png";

interface PDFReportItemProps {
  items: Item[];
}

const renderScoreStarsForPDF = (mark: number) => {
  const scoreStars = [];
  if (mark === 0) {
    // handle the Not Applicable case
    return [
      <Text key="NA" style={styles.tableCell}>
        Not Applicable
      </Text>,
    ];
  } else {
    for (let score = 1; score <= 5; score++) {
      if (score <= mark) {
        scoreStars.push(
          <Image key={score} source={starImg} style={styles.star} />
        );
      } else {
        scoreStars.push(
          <Image key={score} source={GrayStarImg} style={styles.star} />
        );
      }
    }
  }
  return scoreStars;
};

export const PDFReportItem: React.FC<PDFReportItemProps> = ({ items }) => {
  return (
    <>
      <View
        style={{ ...styles.table, marginRight: "10px", marginLeft: "10px" }}
      >
        {items.map((item, index) => (
          <>
            <View key={`${item.Name}item${index}`} style={styles.tableRow}>
              <View
                style={{
                  ...styles.tableCol,
                  width: "40%",
                  backgroundColor: "lightgrey",
                }}
              >
                <Text style={styles.tableCell}>{item.Name}</Text>
              </View>
              <View style={{ ...styles.tableCol, width: "20%" }}>
                <View style={styles.starsContainer}>
                  {renderScoreStarsForPDF(item.Mark)}
                </View>
              </View>
              <View style={{ ...styles.tableCol, width: "40%" }}>
                <Text style={styles.tableCell}>{item.Comment}</Text>
              </View>
            </View>
            {item.Images && item.Images.length > 0 && (
              <View style={styles.tableRow}>
                <View
                  wrap={true}
                  style={{ ...styles.tableCol, width:"100%", marginBottom:"1px" }}
                >
                  {item.Images.map((image,index) => (
                    
                     <Link key={`${item.Name}${index}`} style={{textDecoration:"none", color:"purple", fontSize:"10px", marginBottom:"2px"}} src={image.toString()}>
                      {`>>> Click here to view the image`}
                 </Link>

                  ))}
                </View>
              </View>
            )}
          </>
        ))}
      </View>
    </>
  );
};
