import React from 'react'
import { FaultReport } from '../../../../../_models/Report.model'
import { Col, Container, Row, Table } from 'react-bootstrap'

interface ReportFalultReportProps{
    FaultReports:FaultReport[]
}



export const ReportFalultReport:React.FC<ReportFalultReportProps> = ({FaultReports}) => {
  return (
    <>
            <h3 className='reportFaultReportName'>
            Fault Report
        </h3>

    {FaultReports && FaultReports.length > 0 ? 
<Container>
    <Table className='faultReportTable'>
        <tbody>
        {FaultReports.map(falultReport => (
    <tr>
        <td>
        {falultReport.Item}
        </td>
        <td>
        {falultReport.Comment}
        </td>
        </tr>
))}
        </tbody>
    </Table>


</Container>
    
        
    
    
    : 
    <p>There are no faults reported</p>}
    </>
  )
}
