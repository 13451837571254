import React,{useState,useEffect} from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import { useReportContext } from '../../../../_context/ReportContext'

interface clientItemScoringProps{
    itemScore:number
    updateScore:(score:number)=> void
}





export const ClientItemScoring:React.FC<clientItemScoringProps> = ({
    itemScore,
    updateScore
}) => {

  const {newReport,setNewReport} = useReportContext()

  //this needs to be updated
  const updateTotalScore = (score:number) => {
    let scoreSum = 0;
    let itemCount = 0;
  
    newReport.Areas.forEach(area => {
      area.SubAreas.forEach(subArea => {
        subArea.Items.forEach(item => {
          if (item.Mark > 0) {
            scoreSum += item.Mark;
            itemCount++;
          }
        });
      });
    });
  
    // Calculate the percentage (assuming each item can score a maximum of 5)
    const totalPercentage = (scoreSum / (itemCount * 5)) * 100;
  
    // Update the newReport object with the new total score
    setNewReport(prevReport => ({
      ...prevReport,
      TotalScore: totalPercentage
    }));
  };
  
  // Call this function in the updateScore method
  




  return (
    <ButtonGroup>
{[1, 2, 3, 4, 5].map((score) => (
            <Button
              key={score}
              variant={`${score === itemScore ? "warning" : "outline-secondary"}`}
              onClick={() => updateScore(score)}
              >
              {score}
            </Button>
            
          ))}
            <Button key={0} variant={`${0 === itemScore ? "warning" : "outline-danger"}`} onClick={() => updateScore(0)}>N/A</Button>

    </ButtonGroup>
    
  )
}
