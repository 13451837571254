import React, { useEffect, useState } from "react";
import { getAllSystemUsers } from "../../../../_utils/resourceAPI";
import { useClientContext } from "../../../../_context/ClientContext";
import { Button, Col, Row, Table } from "react-bootstrap";
import { FormSelect } from "../../../../htmlComponents/FormSelect";
import { mandatorySelectorValidator } from "../../../Validations";
import { User } from "../../../../_models/User.model";

export const ClientReportingDetails = () => {
  const [allSystemUsers, setAllSystemUsers] = useState<User[]>([]);
  const [availableSystemUsers, setAvailableSystemUsers] = useState<User[]>([]);
  const { client, setClient } = useClientContext();
  const [selectedSystemUser, setSelectedSystemUser] = useState<string>("");

  const fetchUsers = () => {
    getAllSystemUsers()
      .then((res) => {
        const allUsers: User[] = res.data;
        console.log(allUsers)

        const filteredSystemUsers = allUsers.filter(user => user.role === "system user");         
        setAllSystemUsers(filteredSystemUsers);
        const availableSystemUsers = filteredSystemUsers.filter(
          (user) => !client.ReportingUserIds.includes(user.id)
        );
        setAvailableSystemUsers(availableSystemUsers);
      })
      .catch((error) => {
        console.log("Failed to fetch users", error);
      });
  };

  useEffect(() => {
    fetchUsers();
  }, [client.ReportingUserIds]); // Refetch users when ReportingUserIds change

  const usersToOptions = (usersArray: User[]): Record<string, string> => {
    return usersArray.reduce<Record<string, string>>((acc, user) => {
      acc[`${user.name} - ${user.username} - ${user.email}`] = user.id;
      return acc;
    }, {});
  };

  const userOptions = usersToOptions(availableSystemUsers);

  const handleAddUser = () => {
    if (!selectedSystemUser) return; // Prevent adding if no user is selected
    // Add the selected user ID to the client's reporting users
    setClient((prevClient) => ({
      ...prevClient,
      ReportingUserIds: [...prevClient.ReportingUserIds,selectedSystemUser],
    }));
    // Reset selected user
    setSelectedSystemUser("");
  };

  const handleRemoveUser = (user: User) => {
    // Remove the user ID from the client's reporting users
    setClient((prevClient) => ({
      ...prevClient,
      ReportingUserIds: prevClient.ReportingUserIds.filter((id) => id !== user.id),
    }));
  };

  return (
    <Row>
      <Col sm={10}>
        <FormSelect
          id="ReportingUserSelector"
          label="Select Reporting User"
          optionAndValue={userOptions}
          key="ReportingUserSelector"
          onChange={setSelectedSystemUser}
          required={true}
          value={selectedSystemUser}
          validator={mandatorySelectorValidator}
        />
      </Col>
      <Col sm={2} className="mt-auto">
        <Button className="w-100 btn-warning" onClick={handleAddUser}>
          Add Reporting User
        </Button>
      </Col>
      <Col sm={12}>
        <Table striped bordered hover className="mt-4">
          <thead>
            <tr>
              <th>Name</th>
              <th>Username</th>
              <th>Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {client.ReportingUserIds?.map((id) => {
              const user = allSystemUsers.find((u) => u.id === id);
              return user ? (
                <tr key={id}>
                  <td>{user.name}</td>
                  <td>{user.username}</td>
                  <td>{user.email}</td>
                  <td>
                    <Button variant="danger" onClick={() => handleRemoveUser(user)}>
                      Remove
                    </Button>
                  </td>
                </tr>
              ) : null;
            })}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};
