import React from "react";
import { Row, Col } from "react-bootstrap";
import { FormInput } from "../../../../htmlComponents/FormInput";
import { useCleanerContext } from "../../../../_context/CleanerContext";

interface CleanerAddressDetailsProps {
  addressValidator?: (
    value: string,
    isRequired: boolean,
    inputName: string
  ) => string | null;
  postCodeValidator?: (
    value: string,
    isRequired: boolean,
    inputName: string
  ) => string | null;


  
}
export const CleanerAddressDetails: React.FC<CleanerAddressDetailsProps> = ({
  addressValidator,
  postCodeValidator
}) => {
  const { cleaner, setCleaner } = useCleanerContext();

  return (
    <Row className="mt-4">
      <Col sm={12}>
        <FormInput
          id="CleanerAddressLine1"
          label="Cleaner Address Line 1"
          type="text"
          value={cleaner.Address.Line1}
          required={true}
          onChange={(value: string) =>
            setCleaner({
              ...cleaner,
              Address: {
                ...cleaner.Address,
                Line1: value,
              },
            })
          }
          validator={addressValidator}
        />
      </Col>
      <Col sm={12} className="mt-2">
        <FormInput
          id="CleanerAddressLine2"
          label="Cleaner Address Line 2"
          type="text"
          value={cleaner.Address.Line2}
          required={false}
          onChange={(value: string) =>
            setCleaner({
              ...cleaner,
              Address: {
                ...cleaner.Address,
                Line2: value,
              },
            })
          }
          validator={addressValidator}
        />
      </Col>
      <Col sm={6} className="mt-2">
        <FormInput
          id="CleanerSuberb"
          label="Cleaner Suberb"
          type="text"
          value={cleaner.Address.Suburb}
          required={true}
          onChange={(value: string) =>
            setCleaner({
              ...cleaner,
              Address: {
                ...cleaner.Address,
                Suburb: value,
              },
            })
          }
          validator={addressValidator}
        />
      </Col>
      <Col sm={6} className="mt-2">
        <FormInput
          id="CleanerAddressPostCode"
          label="Cleaner Post Code"
          type="text"
          value={cleaner.Address.Postcode? cleaner.Address.Postcode.toString() : ""}
          required={true}
          onChange={(value: string) =>
            setCleaner({
              ...cleaner,
              Address: {
                ...cleaner.Address,
                Postcode: parseInt(value, 10),
              },
            })
          }
          validator={postCodeValidator}
        />
      </Col>
    </Row>
  );
};
