import React from 'react'
import { Modal } from 'react-bootstrap'
import { useClientContext } from '../../../../_context/ClientContext'

export const ClientDeleteSuccessModal = () => {

    const{setClientDeleted,clientDeleted} = useClientContext()

  return (
    <Modal
    show={clientDeleted}
    size="xl"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    onHide={() => setClientDeleted(false)}
    >
        <Modal.Header  closeButton onHide={() => setClientDeleted(false)}>
           <Modal.Title>Success</Modal.Title> 
        </Modal.Header>
        <Modal.Body>
            Client Deleted Successfully
        </Modal.Body>
        
    </Modal>
  )
}
