import React from "react";
import { Modal } from "react-bootstrap";

import { FaUserCheck } from "react-icons/fa6";
import { useUserContext } from "../../../../_context/UserContext";
import { ReportViewLayout } from "./ReportViewLayout";

interface ReportViewModalProps {
  viewReport: boolean;
  setViewReport: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ReportViewModal: React.FC<ReportViewModalProps> = ({
  viewReport,
  setViewReport,
}) => {
  return (
    <Modal
      show={viewReport}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setViewReport(false)}
    >
      <Modal.Header closeButton onHide={() => setViewReport(false)}>
        <Modal.Title>
          View Report
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReportViewLayout />
      </Modal.Body>
    </Modal>
  );
};
