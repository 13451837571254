import React, { useEffect, useState } from "react";
import { useReportContext } from "../../../_context/ReportContext";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Area } from "../../../_models/Report.model";
import { ClientAreas } from "./Scoring/ClientAreas";
import { OffCanvasClientList } from "./Scoring/OffCanvasClientList";
import { ClientAreaBasedScoringView } from "./Scoring/ClientAreaBasedScoringView";

export const Scoring = () => {
  const { newReport } = useReportContext();
  const [clickedAreas, setClickedAreas] = useState<Set<string>>(new Set());

  const [selectedAreaIndex, setSelectedAreaInex] = useState<number>(0);
  const area_based_scoring_types =
    process.env.REACT_APP_AREA_BASED_SCORING_TYPES?.split(",");



  return (





    <Container>
      {area_based_scoring_types?.includes(newReport.Client.Type.toString()) ? (
        <ClientAreaBasedScoringView />
      ) : (
        <>
          <OffCanvasClientList
            key={"offCanvasMenu"}
            clickedAreas={clickedAreas}
            setClickedAreas={setClickedAreas}
            selectedAreaIndex={selectedAreaIndex}
            setSelectedAreaInex={setSelectedAreaInex}
          />
          <Row>
          <Col sm={11}>
              </Col>
              <Col sm={1} className="text-warning">
                <h2>
                {newReport.TotalScore}
                </h2>
             
            </Col>
          </Row>
          <Row>
            
            <Col sm={12}>
              <ClientAreas selectedAreaIndex={selectedAreaIndex} />
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};
