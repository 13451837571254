export const mandatoryValueValidator = (
  value: string,
  isRequired: boolean,
  selectorLabel?: string
) => {
  if (value.trim() === "" && isRequired) {
    return `${selectorLabel} is required.`;
  } else {
    return null;
  }
};
