import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner, Table } from "react-bootstrap";
import { getAllReports } from "../../../_utils/resourceAPI";
import { Report } from "../../../_models/Report.model";
import { Client } from "../../../_models/Client.model";
import { useNavigate } from "react-router-dom";
import { useReportContext } from "../../../_context/ReportContext";
import { ClientSearchAndFilter } from "../../../htmlComponents/clientSearchAndFilter";
import { useClientContext } from "../../../_context/ClientContext";

export const AddedReports = () => {
  const navigate = useNavigate();
  const [filteredClients, setFilteredClients] = useState<Client[]>([]);
  const [noDataOrLoadingData, setNoDataOrLoadingData] = useState<string>("");
  const {
    setFilteredInternalReportsForSelectedClient,
    setFilteredInspectionReportsForSelectedClient,
  } = useReportContext();
  const { setClient } = useClientContext();
  const {fetchedAllReports,setFetchedAllReports} = useReportContext()

  const [isLoading, setIsLoading] = useState(false);


  const fetchReports = () => {
    setIsLoading(true); // Set loading to true before starting the fetch

    getAllReports()
        .then((res) => {
            const allFetchedReports: Report[] = res.data;
            setFetchedAllReports(allFetchedReports)
        })
        .catch((error) => {
            console.log("Failed to fetch reports", error);
        })
        .finally(() => {
            setIsLoading(false); // Set loading to false after fetching is complete or if an error occurs
        });
};

  useEffect(() => {
    fetchReports();
  }, []);

  
  

  const handleClientReportSelect = (client: Client) => {
    fetchReports()
    const filteredReports = fetchedAllReports.filter((report) =>
      report.Client.Id.includes(client.Id)
    );

    const inspectionReports = filteredReports.filter(
      (report) => report.IsInternalReport === false
    );
    const internalReports = filteredReports.filter(
      (report) => report.IsInternalReport === true
    );

    setFilteredInternalReportsForSelectedClient(internalReports);
    setFilteredInspectionReportsForSelectedClient(inspectionReports);
    setClient(client);
    navigate("/reports/client_reports");
  };

  return (
    <Container>
            <div>
        {isLoading ? (
            <Spinner animation="border" role="status" className="text-center">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        ) : (
           <>
      <ClientSearchAndFilter
        setFiltererdClients={setFilteredClients}
        setDataStatus={setNoDataOrLoadingData}
      />
      {filteredClients.length > 0 ? (
        <Row>
          <Col sm={12}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredClients.map((client) => {
                  return client ? (
                    <tr key={client.Id}>
                      <td>{client.Name}</td>
                      <td>{client.Email}</td>
                      <td>
                        <Button
                          className="w-100"
                          onClick={() => handleClientReportSelect(client)}
                        >
                          View reports
                        </Button>
                      </td>
                    </tr>
                  ) : null;
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col className="text-center">
            {noDataOrLoadingData === "Loading Clients" ? (
              <Spinner animation="border" role="status" />
            ) : (
              <>{noDataOrLoadingData}</>
            )}
          </Col>
        </Row>
      )}

           </>
        )}
    </div>
      
    </Container>
  );
};
