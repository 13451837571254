import { Admin } from "./appComponents/Admin/Admin";
import { Login } from "./appComponents/Login/Login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "./appComponents/PrivateRoute/PrivateRoute";
import { AdminLayout } from "./appComponents/Admin/AdminLayout";
import { AddClients } from "./appComponents/Admin/AddClients/AddClients";
import { AddCleaners } from "./appComponents/Admin/AddCleaners/AddCleaners";
import { ViewCleaners } from "./appComponents/Admin/ViewCleaners/ViewCleaners";
import { AddedClients } from "./appComponents/Admin/AddClients/AddClientsComponents/AddedClients";
import { AddUsers } from "./appComponents/Admin/AddUsers/AddUsers";
import { ViewUsers } from "./appComponents/Admin/ViewUsers/ViewUsers";
import { ReportLayout } from "./appComponents/Reporting/ReportingLayout";
import { Reports } from "./appComponents/Reporting/Reports";
import { AddedReports } from "./appComponents/Reporting/ViewReports/ClientSelectionToViewReports";
import { ClientReports } from "./appComponents/Reporting/ViewReports/ClientReports";
import {CreateReportSelectClientLayout} from './appComponents/Reporting/CreateReport/ClientSelectionToCreateReport'
import { CreateReportLayout } from "./appComponents/Reporting/CreateReport/CreateReportLayout";
import { ConfirmClient } from "./appComponents/Reporting/CreateReport/ConfirmClient";
import { ClientConsent } from "./appComponents/Reporting/CreateReport/ClientConsent";
import { Scoring } from "./appComponents/Reporting/CreateReport/CreateReportScoring";
import { ClientAndClientComments } from "./appComponents/Reporting/CreateReport/Scoring/ClientAndClientComments";
import { ClientAndCNASignatures } from "./appComponents/Reporting/CreateReport/Scoring/ClientAndCNASignatures";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="reports"
            element={
              <PrivateRoute>
              <ReportLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<Reports />} />
            <Route path="view_reports" element={<AddedReports />} />
            <Route path="client_reports" element={<ClientReports />} />
            <Route path="select_client_for_new_report" element={<CreateReportSelectClientLayout/>}/>
            <Route path="create_new_report" element={<CreateReportLayout/>}>
              <Route index element={<ConfirmClient/>}/>
              <Route path="client-consent" element={<ClientConsent/>}/>
              <Route path="scoring" element={<Scoring/>}/>
              <Route path="comments" element={<ClientAndClientComments/>}/>
              <Route path="signatures" element={<ClientAndCNASignatures/>}/>              
            </Route>
          </Route>
          <Route path="*" element={<p>Page Not Found</p>} /> {/* 404 page */}
          <Route
            path="admin"
            element={
              <PrivateRoute>
                <AdminLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<Admin />} />
            <Route path="add_new_client" element={<AddClients />} />
            <Route path="add_system_users" element={<AddUsers />} />
            <Route path="add_new_cleaner" element={<AddCleaners />} />
            <Route path="view_cleaners" element={<ViewCleaners />} />
            <Route path="view_clients" element={<AddedClients />} />
            <Route path="view_users" element={<ViewUsers />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
