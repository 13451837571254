import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ClientConcent } from '../../../../../_models/Report.model'
import { useReportContext } from '../../../../../_context/ReportContext';
import { ClientType } from '../../../../../_models/Client.model';

interface ReportConfirmationViewProps{
    ClientConsent:ClientConcent,
    ClientSignature:string,
    CNASignature:string,
}


export const ReportConfirmationView:React.FC<ReportConfirmationViewProps> = ({
    ClientSignature,
    ClientConsent,
    CNASignature
}) => {
    const area_based_scoring_types =
    process.env.REACT_APP_AREA_BASED_SCORING_TYPES?.split(",");
    const {currentViewingReport} = useReportContext()


    const ReportConfirmedBy = () => {
        return (
            <Row>
                <Col sm={12}>
                <strong>Report confirmed by</strong>
                </Col>
                <Col sm={12}>
                {ClientSignature ? <img className='w-25' src={ClientSignature} alt="ClientSignature"/> : <span className='text-secondary'>Client signature not found</span>}
                </Col>
                <Col sm={12}>
                    {currentViewingReport.Client.Type === ClientType.Domestic ? <small>
                    {currentViewingReport.Client.Name}
                </small> :                 <small>
                    {ClientConsent.Name} ({ClientConsent.Designation})
                </small> }

                </Col>
            </Row>
        )
    }


  return (
    <Container>

        <Row>
            <Col sm={6}>
            {ClientConsent.ConcentProvided 
           ? (
            <ReportConfirmedBy/>            
            ) : 
            (

                
            <Row>
                        {area_based_scoring_types?.includes(
            currentViewingReport.Client.Type.toString()
          ) ? (
          <ReportConfirmedBy/>
          ) : (<Col sm={12}>
            <span>Client consent is not provided due to - {ClientConsent.NoConcentReason}</span>
        </Col>) }
                
            </Row>
            )
            }
                
            </Col>
            <Col sm={6}>
                <Row>
                <Col sm={12}>
                <strong>Inspected by</strong>
                </Col>
                <Col>
                {CNASignature ? <img className='w-25' src={CNASignature} alt="ClientSignature"/> : <span className='text-secondary'>CNA signature not found</span>}
                </Col>
                <Col sm={12}>
                <small>
                    {currentViewingReport.InspectedBy} (CNA Cleaning Services)
                </small>
                </Col>
                </Row>
                
            </Col>
        </Row>
    </Container>
  )
}
