import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

interface ReportRemarksPageProps{

    ClientRemarks:string,
    CNARemarks:string
}

export const ReportRemarksView:React.FC<ReportRemarksPageProps> = ({
    ClientRemarks,
    CNARemarks
}) => {
  return (
    <>
    <h3 className='reportFaultReportName'>
            Client Remarks
        </h3>
    <Container>
    <Row>
        <Col sm={12}>
            {ClientRemarks ? <p>{ClientRemarks}</p> : <p> There are no client remarks recorded under this report</p>}
            </Col>
    </Row>
    </Container>
    <h3 className='reportFaultReportName'>
            CNA Remarks
        </h3>
    <Container>
    <Row>
        <Col sm={12}>
            {CNARemarks ? <p>{CNARemarks}</p> : <p> There are no CNA remarks recorded under this report</p>}
            </Col>
    </Row>
    </Container>
    
    </>
  )
}
