import React, {useState} from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FormInput } from "../../../../htmlComponents/FormInput";
import { useCleanerContext } from "../../../../_context/CleanerContext";
import { FaUserPlus } from "react-icons/fa6";
import SuccessfullSubmission from "../../Notifications/SuccessfullSubmission";
import { submitCleaner } from "../../../../_utils/resourceAPI";
import EmptyCleaner from '../../../../_resources/JsonData/EmptyCleaner.json'
import { formatDateForInput, parseDate } from "../../../../_constrains/dates";

interface CleanerBasicDataProps {
  cleanerNameValidator: (
    value: string,
    isRequired: boolean,
    inputName: string
  ) => string | null;
  cleanerEmailValidator: (
    value: string,
    isRequired: boolean,
    inputName: string
  ) => string | null;
  cleanerContactNumberValidator: (
    value: string,
    isRequired: boolean,
    inputName: string
  ) => string | null;
}

export const CleanerBaiscDetails: React.FC<CleanerBasicDataProps> = ({
  cleanerContactNumberValidator,
  cleanerEmailValidator,
  cleanerNameValidator,
}) => {
  const { cleaner, setCleaner } = useCleanerContext();
 

  return (
    <Row>
      <Col sm={12} >
        <h5>
          {" "}
          <FaUserPlus /> Add a New Cleaner{" "}
        </h5>
        <hr />
      </Col>
      <Col sm={12} className="mt-2">
        <FormInput
          id="CleanersName"
          label="Cleaner Name"
          type="text"
          value={cleaner.Name}
          required={true}
          onChange={(value: string) => setCleaner({ ...cleaner, Name: value })}
          validator={cleanerNameValidator}
        />
      </Col>
      <Col sm={12} className="mt-2">
        <FormInput
          id="CleanerEmail"
          label="Cleaner Email"
          type="email"
          value={cleaner.Email}
          required={true}
          onChange={(value: string) => setCleaner({ ...cleaner, Email: value })}
          validator={cleanerEmailValidator}
        />
      </Col>
      <Col sm={12} className="mt-2">
        <FormInput
          id="CleanerContact"
          label="Cleaner Contact Number"
          type="text"
          value={cleaner.Phone}
          required={true}
          onChange={(value: string) => setCleaner({ ...cleaner, Phone: value })}
          validator={cleanerContactNumberValidator}
        />
      </Col>
      <Col sm={12} className="mt-2">
  <FormInput
    id="CleanerStartDate"
    label="Cleaner Start Date"
    type="date"
    value={cleaner.StartDate ? formatDateForInput(cleaner.StartDate) : ''}
    required={true}
    onChange={(value) => {
     
      if (!value) {
        setCleaner({ ...cleaner, StartDate: '' });
        return;
      }

      // Proceed only if the value is a valid date
      const selectedDate = parseDate(value);
      console.log(cleaner)
      console.log(selectedDate)
      console.log(value)
      console.log(cleaner.StartDate)
      if (!isNaN(selectedDate.getTime())) {
        // Convert the Date object to an ISO string
        const isoDate = selectedDate.toISOString();
        // Update the state with the ISO string
        setCleaner({ ...cleaner, StartDate: isoDate });
      }

    }}
  />
</Col>
<Col sm={12} className="mt-2">
  <FormInput
    id="CleanerEndDate"
    label="Cleaner End Date"
    type="date"
    value={cleaner.EndDate ? cleaner.EndDate.split('T')[0] : ''}
    required={true}
    onChange={(value) => {
      if (!value) {
        // Handle the case when the date is cleared
        setCleaner({ ...cleaner, EndDate: '' });
        return;
      }

      // Proceed only if the value is a valid date
      const selectedDate = parseDate(value);;
      if (!isNaN(selectedDate.getTime())) {
        // Check if the end date is after the start date
        const startDate = new Date(cleaner.StartDate);
        if (selectedDate > startDate) {
          // Convert the Date object to an ISO string
          const isoDate = selectedDate.toISOString();
          // Update the state with the ISO string
          setCleaner({ ...cleaner, EndDate: isoDate });
        } else {
          // Handle the case when the end date is before the start date
          alert("End Date must be after the Start Date.");
          // Optionally, reset the end date
          // setCleaner({ ...cleaner, EndDate: '' });
        }
      }
    }}
  />
</Col>


      
    </Row>
  );
};
