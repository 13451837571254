import React from "react";
import { Row, Col } from "react-bootstrap";
import { FormInput } from "../../../../htmlComponents/FormInput";
import { useClientContext } from "../../../../_context/ClientContext";

interface ClientAddressDetailsProps {
  addressValidator?: (
    value: string,
    isRequired: boolean,
    inputName: string
  ) => string | null;
  postCodeValidator?: (
    value: string,
    isRequired: boolean,
    inputName: string
  ) => string | null;


  
}
export const ClientAddressDetails: React.FC<ClientAddressDetailsProps> = ({
  addressValidator,
  postCodeValidator
}) => {
  const { client, setClient } = useClientContext();

  return (
    <Row className="mt-4">
      <Col sm={12}>
        <FormInput
          id="ClientAddressLine1"
          label="Client Address Line 1"
          type="text"
          value={client.Address.Line1}
          required={true}
          onChange={(value: string) =>
            setClient({
              ...client,
              Address: {
                ...client.Address,
                Line1: value,
              },
            })
          }
          validator={addressValidator}
        />
      </Col>
      <Col sm={12} className="mt-2">
        <FormInput
          id="ClientAddressLine2"
          label="Client Address Line 2"
          type="text"
          value={client.Address.Line2}
          required={false}
          onChange={(value: string) =>
            setClient({
              ...client,
              Address: {
                ...client.Address,
                Line2: value,
              },
            })
          }
          validator={addressValidator}
        />
      </Col>
      <Col sm={6} className="mt-2">
        <FormInput
          id="ClientSuberb"
          label="Client Suberb"
          type="text"
          value={client.Address.Suburb}
          required={true}
          onChange={(value: string) =>
            setClient({
              ...client,
              Address: {
                ...client.Address,
                Suburb: value,
              },
            })
          }
          validator={addressValidator}
        />
      </Col>
      <Col sm={6} className="mt-2">
        <FormInput
          id="ClientAddressPostCode"
          label="Client Post Code"
          type="text"
          value={client.Address.Postcode? client.Address.Postcode.toString() : ""}
          required={true}
          onChange={(value: string) =>
            setClient({
              ...client,
              Address: {
                ...client.Address,
                Postcode: parseInt(value, 10),
              },
            })
          }
          validator={postCodeValidator}
        />
      </Col>
    </Row>
  );
};
