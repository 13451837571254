import React, { useState } from "react";
import { FormInput } from "../../../../htmlComponents/FormInput";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa6";
import { useReportContext } from "../../../../_context/ReportContext";
import { validateName } from "../../../Validations";
import { useNavigate } from "react-router-dom";

interface ClientGotConsentProps {}

export const ClientGotConsent: React.FC<ClientGotConsentProps> = ({}) => {
 

  const { setNewReport,newReport } = useReportContext();
  const navigate = useNavigate()




  const updateConsentProviderName = (name:string) => {
    setNewReport(newReport => ({
      ...newReport,
      ClientConcent: {
        ...newReport.ClientConcent,
        Name: name
      }
    }));
  };
  

  const updateConsentProviderDesignation = (designation:string) => {
    setNewReport(newReport => ({
      ...newReport,
      ClientConcent: {
        ...newReport.ClientConcent,
        Designation: designation
      }
    }));
  };
  
  


  const handleProceedButtonClick = () => {
    setNewReport((newReport) => ({
      ...newReport,
      ClientConcent: {
        ...newReport.ClientConcent,
        ConcentProvided: true,
        SelfInspection: false,
        NoConcentReason: "",
      },
    }));
    navigate('/reports/create_new_report/scoring')
  };


  const isConsentProviderDetailsValid = () => {
    return (
      !validateName(newReport.ClientConcent.Name, true) &&
      !validateName(newReport.ClientConcent.Designation, true)
    );
  };

  return (
    <Container className="pt-4">
      <Row>
        <h5>Details of the consent provider</h5>
        <Col sm={6}>
          <FormInput
            id="consentProviderName"
            label="Consent Provider"
            type="string"
            onChange={updateConsentProviderName}
            value={newReport.ClientConcent.Name}
            validator={validateName}
          />
        </Col>
        <Col sm={6}>
          <FormInput
            id="consentProviderDesignation"
            label="Designation"
            type="string"
            onChange={updateConsentProviderDesignation}
            value={newReport.ClientConcent.Designation}
            validator={validateName}
          />
        </Col>
        <Col sm={12}>
          <Row className="mt-2">
            <Col></Col>

            <Col sm={4}></Col>
            <Col sm={4}>
              <Button
                variant="success"
                className="generalButton"
                onClick={handleProceedButtonClick}
                disabled={!isConsentProviderDetailsValid()}
              >
                Proseed to Inspections <FaArrowRight />
              </Button>
            </Col>
            <Col></Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
