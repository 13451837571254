import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: "20px",
  },
  areaTitle: {
    fontSize: "15px",
    color: "purple",
  },

  secondaryTitles: {
    fontSize: "15px",
    color: "purple",
  },

  paragapthText: {
    fontSize: "11px",
    color: "black",
  },

  subAreaTitle: {
    fontSize: "12px",
    color: "black",
  },
  contentSection: {
    margin: "10px",
    flexDirection: "column",
    maxHeight: "650px",
  },
  textarea: {
    fontSize: "12px",
    flexWrap: "wrap",
  },
  headerTitleSection: {
    paddingTop: "20px",
    flexDirection: "column",
    width: "100%",
  },

  headerImageSection: {
    flexDirection: "column",
    width: "100%",
  },

  headerContentSection: {
    flexDirection: "column",
    width: "100%",
  },

 row: {
    flexDirection: "row",
  },

  col: {
    flexDirection: "column",
  },

  headerContainerTextFullColumn:{
    fontSize: "12px",
  },


  heading: {
    fontSize: "25px",
    fontWeight: "light" ,
    textAlign: "right",
    paddingBottom: "10px",
  },

  areaSection: {
    paddingTop: "20px",
    flexDirection: "column",
    width: "100%",
  },

  subAreaSection: {
    padding: "10px",
    flexDirection: "column",
    width: "100%",
  },

  table: {
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: '#000',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#000',
  },
  tableColHeader: {
    width: '25%',
    borderBottomWidth: 1,
    backgroundColor: '#f0f0f0',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableCol: {
    width: '25%',
    padding:"5px",
    borderRightWidth: 0.5,
    borderColor: '#000',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  tableCellHeader: {
    fontSize: 12,
    fontWeight: 700,
    padding: 8,
  },
  tableCell: {
    margin: 'auto',
    marginVertical: 5,
    fontSize: 10,
    textAlign: 'center',
  },
  star: {
    width: 12,    // Adjust the width as needed
    height: 12,   // Adjust the height as needed
    margin: 1 // Gives some space between stars
  },

  starsContainer: {
    flexDirection: 'row',  // To display stars horizontally
    alignItems: 'center',  // Center stars vertically within the container
    justifyContent: 'center' // Starts the stars from the left side of the container
  }

});
