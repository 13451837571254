import React from 'react'
import { Modal, Spinner } from 'react-bootstrap'

interface LoadingSpinnerModalProps{
    Message?:string,
    ShowModal:boolean
}


export const LoadingSpinnerModal:React.FC<LoadingSpinnerModalProps> = ({
    Message,
    ShowModal
}) => {
  return (
    <Modal show={ShowModal} centered>
      <Modal.Body className="text-center">
        <Spinner animation="border" variant='warning' role="status">
          <span className="visually-hidden">Loading...</span>
          <br/>
          {Message && <span>
            {Message}
          </span>}
        </Spinner>
      </Modal.Body>
    </Modal>
  )
}
