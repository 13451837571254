import React, { useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Webcam from "react-webcam";

export interface CameraProps {
  onCapture: (image: string) => void;
  onCaptureCompleted: () => void; // Add this line
}

const Camera: React.FC<CameraProps> = ({ onCapture, onCaptureCompleted }) => {
  const webcamRef = useRef<Webcam>(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    onCapture(imageSrc || "");
    onCaptureCompleted();
  }, [webcamRef, onCapture, onCaptureCompleted]);

  return (
    <Container>
      <Row>
        <Col className="d-flex justify-content-center">
          <Webcam
            audio={false}
            ref={webcamRef}
            className="camView"
            videoConstraints={{
              facingMode: "environment",
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col></Col>
        <Col>
          <Button className="w-100 mt-2" onClick={capture}>
            Capture
          </Button>
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center mt-1">
          <small>
            After the photo is taken, make sure to upload them if you need them
            to in the report.
          </small>
        </Col>
      </Row>
    </Container>
  );
};

export default Camera;
