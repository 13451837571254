import React, { ReactElement } from 'react';

import { useUserContext } from '../../_context/UserContext'
import { Navigate } from 'react-router-dom';
import { useLoggedInUserContext } from '../../_context/LoggedInUserContext';

interface PrivateRouteProps {
  children: ReactElement | ReactElement[];
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({children}) => {

  const {isLogged} = useUserContext();
  // const {loggedUser} = useLoggedInUserContext()
  return isLogged ? <>{children}</> : <Navigate to="/" />;
  
}

export default PrivateRoute;
