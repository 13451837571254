import React, { ReactNode, createContext, useContext, useState } from "react";



interface CreateReportnotificationContextProps{
    notification: string;
    setNotification: React.Dispatch<React.SetStateAction<string>>;
    displayNotification: boolean;
    setDisplayNotification: React.Dispatch<React.SetStateAction<boolean>>;
}



const CreateReportNotificationContext = createContext<CreateReportnotificationContextProps | null>(null)

export const useCreateReportNotificationContext = () => {
    const context = useContext(CreateReportNotificationContext)
    if (!context) {
        throw new Error('useCreateReportNotificationContext must be used within a ClientContextProvider');
      }
      return context;
    };


interface CreateReportNotificationContextProviderProps {
        children: ReactNode;
      }

 export const CreateReportNotificationContextProvider: React.FC<CreateReportNotificationContextProviderProps> = ({ children }) => {

    const [notification,setNotification] = useState("")
    const [displayNotification,setDisplayNotification] = useState(false)

    return (
        <CreateReportNotificationContext.Provider value={{notification,setNotification,displayNotification,setDisplayNotification}}>
          {children}
        </CreateReportNotificationContext.Provider>
      );
        
    }