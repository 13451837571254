import React,{useEffect,useState} from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { FormInput } from '../../../../htmlComponents/FormInput'
import { useUserContext } from '../../../../_context/UserContext';
import { FormSelect } from '../../../../htmlComponents/FormSelect';
import {  FaUserTie } from 'react-icons/fa6';
import EmptyUser from '../../../../_resources/JsonData/EmptyUser.json'
import { submitUser } from '../../../../_utils/resourceAPI';
import SuccessfullSubmission from '../../Notifications/SuccessfullSubmission';

interface UserBasicDetaisProps{
  nameValidator: (
    value: string,
    isRequired: boolean,
    inputName: string
  ) => string | null;
  userNameValidator: (
    value: string,
    isRequired: boolean,
    inputName: string
  ) => string | null;
  emailValidator: (
    value: string,
    isRequired: boolean,
    inputName: string
  ) => string | null;
  passwordValidator: (
    value: string,
    isRequired: boolean,
    inputName: string
  ) => string | null;
  selectValidator: (
    value: string,
    isRequired: boolean,
    labelName: string
  ) => string | null;
}

export const UserBasicDetails:React.FC<UserBasicDetaisProps> = ({
  nameValidator,
  userNameValidator,
  emailValidator,
  passwordValidator,
  selectValidator
}) => { 

  

  const{newUser,setNewUser} = useUserContext()
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleShowSuccessModal = () => setShowSuccessModal(true);

  const resetForm = () => {
    setNewUser(EmptyUser);
  };

  const submitUserHandler = (e: React.FormEvent) => {
    e.preventDefault();
    submitUser(newUser)
      .then((res) => {
        handleShowSuccessModal();
        resetForm();
      })
      .catch((error) => {
        console.log("Something went wrong");
      });
  };

  useEffect(() => {
    console.log("New User:", newUser);
  },[newUser])

  const isFormValid = () => {
    return (
      !nameValidator(newUser.name, true, "") &&
      !userNameValidator(newUser.username, true, "") &&
      !emailValidator(newUser.email, true, "") &&
      !passwordValidator(newUser.password, true, "") &&
      !selectValidator(newUser.role, true, "")
    );
  };




  return (
    <Row>
            <Col sm={12}>
        <h5>
          <FaUserTie/> Add a new user
        </h5>
        <hr />
      </Col>
      <Col sm={12}>
        <FormInput
          id="SysUserName"
          label="Name"
          type="text"
          value={newUser.name}
          required={true}
          onChange={(value: string) => setNewUser({...newUser,name:value})}
          validator={nameValidator}
        />
      </Col>
        <Col sm={12}>
        <FormInput
          id="SysUserUserName"
          label="User Name"
          type="text"
          value={newUser.username}
          required={true}
          onChange={(value: string) => setNewUser({...newUser,username:value})}
          validator={userNameValidator}
        />
      </Col>
      <Col sm={12}>
        <FormInput
          id="SysUserEmail"
          label="Email"
          type="email"
          value={newUser.email}
          required={true}
          onChange={(value: string) => setNewUser({...newUser,email:value})}
          validator={emailValidator}
        />
      </Col>
      <Col sm={12}>
        <FormInput
          id="SysUserPassword"
          label="Password"
          type="password"
          value={newUser.password}
          required={true}
          onChange={(value: string) => setNewUser({...newUser,password:value})}
          validator={passwordValidator}
        />
      </Col>
      <Col sm={12} className='mt-2'>
      <FormSelect
          id="Admin Role"
          label="User Role"
          optionAndValue={{"Admin":"admin","System user":"system user"}}
          key={"cleanerSelector"}
          onChange={(value: string) => setNewUser({...newUser,role:value})}
          required={true}
          value={newUser.role}
          validator={selectValidator}
        />
          </Col>
          <Col sm={12} className="mt-1">
        <Button className="w-100 btn-success" onClick={submitUserHandler} disabled={!isFormValid()}>
          Add New User
        </Button>
      </Col>
      <SuccessfullSubmission show={showSuccessModal} onHide={handleCloseSuccessModal} successMessage="User Added Successfully"/>
    </Row>
  )
}
